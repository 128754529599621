import axios from "axios";
import {
  BACKEND_URL_2,
  CLEAR_INSTANCE_DATA,
  CLEAR_INSTANCE_DATA_SAVE,
  LIST_DETAILED_INSTANCE_FAILED,
  LIST_DETAILED_INSTANCE_SUCCESS,
  LIST_EVENT_BASED_INSTANCE_FAILED,
  LIST_EVENT_BASED_INSTANCE_SUCCESS,
  LIST_INSTANCE_FOR_APPROVAL,
  LIST_MY_COMPLIANCE_DETAIL_FAILED,
  LIST_MY_COMPLIANCE_DETAIL_SUCCESS,
  LIST_SAVED_DETAILED_INSTANCE_FAILED,
  LIST_SAVED_DETAILED_INSTANCE_SUCCESS,
  LIST_TIME_BASED_INSTANCE_FAILED,
  LIST_TIME_BASED_INSTANCE_SUCCESS,
  RESET_ALERT_FOR_MY_COMPLIANCE,
  SET_ALERT_FOR_MY_COMPLIANCE,
  SET_LOGOUT_PROVISION,
  LIST_INSTANCE_FOR_EVENTS,
  SET_USER_ROLE,
  LIST_TASK_INSTANCE_DATA,
  LIST_MY_COMPLIANCE_1_DATA,
  LIST_MY_COMPLIANCE_2_DATA,
  LIST_APPROVAL_LOADING,
  LIST_EVENTS_LOADING,
  LIST_TASKS_LOADING,
  LIST_DASHBOARD_DATA_LOADING,
  LIST_DASHBOARD_DATA,
  LIST_CERTIFCATE_DATA_LOADING,
  LIST_CERTIFCATE_DATA,
  GET_REASONS,
  REPORT_COMPLIANCE_STATUS_LOADING,
  REPORT_COMPLIANCE_STATUS,
  REPORT_DELAYED_COMPLIANCE_LOADING,
  REPORT_DELAYED_COMPLIANCE,
  REPORT_EVENT_CHECKLIST,
  REPORT_EVENT_CHECKLIST_LOADING,
  REPORT_EVENT_OCCURANCE_LOADING,
  REPORT_EVENT_OCCURANCE,
  REPORT_CHECKER_VERIFICATION,
  REPORT_CHECKER_VERIFICATION_LOADING,
  REPORT_ACTION_PLAN_LOADING,
  REPORT_ACTION_PLAN,
  REPORT_CERTIFICATE_GENERATION_LOADING,
  REPORT_CERTIFICATE_GENERATION,
  REPORT_COMPLIANCE_COUNT_LOADING,
  REPORT_COMPLIANCE_COUNT,
  REPORT_USER_LOGIN_LOADING,
  REPORT_USER_LOGIN,
  REPORT_NOT_APPLICABLE_LOADING,
  REPORT_NOT_APPLICABLE,
  GLOBAL_ALERT_SYSTEM,
  SHOW_APPROVAL_TAB,
  RESET_IDLE_TIME,
  UPLOADING_FILES_MY_COMPLIANCE,
  INSTANCE_LOADING_DATA,
  SET_DOWNLOAD_CERTIFICATE_PARAMETER,
  SET_DOWNLOAD_ACTIVITY_LOG,
  COMPLIANCE_DATA_DASHBOARD,
} from "../../actions/types";
import store from "../../redux/store";

let getLoggedinRoleAbort = null;
export const getLoggedinRole = (company, division) => async (dispatch) => {
  if (getLoggedinRoleAbort !== null) {
    getLoggedinRoleAbort.cancel("New Call made");
    getLoggedinRoleAbort = null;
  }
  getLoggedinRoleAbort = axios.CancelToken.source();
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-division-user-role",
    data: {
      company,
      division,
    },
    cancelToken: getLoggedinRoleAbort.token,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({ type: SET_USER_ROLE, payload: response.data.role_name });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({ type: SET_USER_ROLE, payload: null });
      } else {
        dispatch({ type: SET_USER_ROLE, payload: null });
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_ROLE, payload: null });
    });
};

let listComplianceForUserAbort = null;
export const listComplianceForUser =
  (company, division) => async (dispatch) => {
    if (listComplianceForUserAbort !== null) {
      listComplianceForUserAbort.cancel("New Call made");
      listComplianceForUserAbort = null;
    }
    listComplianceForUserAbort = axios.CancelToken.source();
    await dispatch({ type: LIST_MY_COMPLIANCE_1_DATA, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/list-compliance-by-user",
      data: { company_id, company, division },
      headers: config,
      cancelToken: listComplianceForUserAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({ type: LIST_MY_COMPLIANCE_1_DATA, payload: false });
          dispatch({
            type: LIST_MY_COMPLIANCE_DETAIL_SUCCESS,
            payload: response.data.data,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: LIST_MY_COMPLIANCE_1_DATA, payload: false });

          await dispatch({
            type: LIST_MY_COMPLIANCE_DETAIL_FAILED,
          });
        } else {
          await dispatch({ type: LIST_MY_COMPLIANCE_1_DATA, payload: false });
          await dispatch({
            type: LIST_MY_COMPLIANCE_DETAIL_FAILED,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_MY_COMPLIANCE_DETAIL_FAILED,
        });
      });
  };

let listTimeInstanceAbort = null;
export const listTimeInstance =
  (company, division, div_compliance_id) => async (dispatch) => {
    if (listTimeInstanceAbort !== null) {
      listTimeInstanceAbort.cancel("New Call made");
      listTimeInstanceAbort = null;
    }
    listTimeInstanceAbort = axios.CancelToken.source();
    await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: true });
    await dispatch({
      type: LIST_TIME_BASED_INSTANCE_SUCCESS,
      payload: { data: [], type: "TIME" },
    });
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/list-time-instances",
      data: { company_id, company, division, div_compliance_id },
      headers: config,
      cancelToken: listTimeInstanceAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });

          await dispatch({
            type: LIST_TIME_BASED_INSTANCE_SUCCESS,
            payload: { data: response.data.data, type: "TIME" },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });
          await dispatch({
            type: LIST_TIME_BASED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });
          await dispatch({
            type: LIST_TIME_BASED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_TIME_BASED_INSTANCE_FAILED,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

let listEventInstanceAbort = null;
export const listEventInstance =
  (company, division, div_compliance_id) => async (dispatch) => {
    if (listEventInstanceAbort !== null) {
      listEventInstanceAbort.cancel("New Call made");
      listEventInstanceAbort = null;
    }
    listEventInstanceAbort = axios.CancelToken.source();
    await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: true });
    await dispatch({
      type: LIST_TIME_BASED_INSTANCE_SUCCESS,
      payload: { data: [], type: "TIME" },
    });
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/list-event-instances",
      data: { company_id, company, division, div_compliance_id },
      headers: config,
      cancelToken: listEventInstanceAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });
          await dispatch({
            type: LIST_EVENT_BASED_INSTANCE_SUCCESS,
            payload: { data: response.data.data, type: "EVENT" },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });
          await dispatch({
            type: LIST_EVENT_BASED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({ type: LIST_MY_COMPLIANCE_2_DATA, payload: false });
          await dispatch({
            type: LIST_EVENT_BASED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_EVENT_BASED_INSTANCE_FAILED,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

let getInstanceDetailsAbort = null;
export const getInstanceDetails =
  (company, division, types, task_id, report_number) => async (dispatch) => {
    if (getInstanceDetailsAbort !== null) {
      getInstanceDetailsAbort.cancel("New Call made");
      getInstanceDetailsAbort = null;
    }
    getInstanceDetailsAbort = axios.CancelToken.source();
    await dispatch({ type: INSTANCE_LOADING_DATA, payload: true });
    await dispatch({ type: CLEAR_INSTANCE_DATA });
    dispatch(
      getInstanceSavedData(company, division, types, task_id, report_number)
    );
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/get-division-instance-detail",
      data: { company_id, company, division, types, task_id, report_number },
      headers: config,
      cancelToken: getInstanceDetailsAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          var data = {
            details: response.data.data.details,
            documents: response.data.data.documents,
            overview: response.data.data.overview,
            remarks: response.data.data.remarks,
            steps: response.data.data.steps,
          };
          await dispatch({ type: INSTANCE_LOADING_DATA, payload: false });
          await dispatch({
            type: LIST_DETAILED_INSTANCE_SUCCESS,
            payload: { data: data },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: INSTANCE_LOADING_DATA, payload: false });
          await dispatch({
            type: LIST_DETAILED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({ type: INSTANCE_LOADING_DATA, payload: false });
          await dispatch({
            type: LIST_DETAILED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_DETAILED_INSTANCE_FAILED,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const triggerEventInstance =
  (company, division, data, div_compliance_id, role) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/event-instance-action",
      data: { company_id, company, division, data, role },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(listEventInstance(company, division, div_compliance_id));
          dispatch(listApprovaltask(company, division));
          dispatch(listTaskData(company, division));
          dispatch(listEventTask(company, division));
          dispatch(
            getInstanceDetails(
              parseInt(sessionStorage.getItem("company_selected_top")),
              parseInt(sessionStorage.getItem("division_selected_top")),
              sessionStorage.getItem("intanceType"),
              data[0].id,
              data[0].report_number
            )
          );
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "PASS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const triggerEventInstanceAll =
  (company, division, data, div_compliance_id) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/event-instance-action",
      data: { company_id, company, division, data },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(listEventTask(company, division));
          dispatch(listApprovaltask(company, division));
          dispatch(listTaskData(company, division));
          dispatch(listComplianceForUser(company, division));
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "PASS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

let getInstanceSavedDataAbort = null;
export const getInstanceSavedData =
  (company, division, types, task_id, report_number) => async (dispatch) => {
    if (getInstanceSavedDataAbort !== null) {
      getInstanceSavedDataAbort.cancel("New Call made");
      getInstanceSavedDataAbort = null;
    }
    getInstanceSavedDataAbort = axios.CancelToken.source();
    await dispatch({ type: CLEAR_INSTANCE_DATA_SAVE });
    const config = {
      "Content-Type": "application/json",
    };
    const company_id = store.getState().auth.data.company_id;
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/get-div-instance",
      data: { company_id, company, division, types, task_id, report_number },
      headers: config,
      cancelToken: getInstanceSavedDataAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: LIST_SAVED_DETAILED_INSTANCE_SUCCESS,
            payload: { data: response.data.data },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: LIST_SAVED_DETAILED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          await dispatch({
            type: LIST_SAVED_DETAILED_INSTANCE_FAILED,
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_SAVED_DETAILED_INSTANCE_FAILED,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const deleteFile = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/delete-files",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(
          getInstanceDetails(
            values.company,
            values.division,
            values.types,
            values.task_id,
            values.report_number
          )
        );
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "PASS",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: RESET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

export const saveInstanceData = (values, isFile) => async (dispatch) => {
  dispatch({
    type: UPLOADING_FILES_MY_COMPLIANCE,
    payload: { message: "Saving details", isUploading: true },
  });
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "operations/save-div-instance",
    data: values,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        dispatch(listApprovaltask(values.company, values.division));
        dispatch(listTaskData(values.company, values.division));
        // dispatch(listEventTask(values.company, values.division));
        dispatch(
          getInstanceDetails(
            values.company,
            values.division,
            values.types,
            values.task_id,
            values.report_number
          )
        );
        if (!isFile) {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "PASS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      dispatch({
        type: UPLOADING_FILES_MY_COMPLIANCE,
        payload: {
          message: null,
          isUploading: false,
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

export const saveInstanceDataSubmit =
  (
    company,
    division,
    types,
    task_id,
    report_number,
    div_compliance_id,
    values,
    dataType
  ) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/save-div-instance",
      data: values,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            submitInstanceData(
              company,
              division,
              types,
              task_id,
              report_number,
              div_compliance_id,
              dataType
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };
export const submitInstanceDataInit =
  (
    company,
    division,
    types,
    task_id,
    report_number,
    div_compliance_id,
    values,
    fileData,
    dataType = null
  ) =>
  async (dispatch) => {
    if (fileData !== null) {
      await dispatch(
        uploadInstanceData(
          fileData.company,
          fileData.division,
          fileData.category,
          fileData.is_company_level,
          fileData.cpn_no,
          fileData.data,
          fileData.task_id,
          fileData.report_number,
          fileData.types,
          {
            company,
            division,
            types,
            task_id,
            report_number,
            div_compliance_id,
            values,
            dataType,
          }
        )
      );
    } else {
      await dispatch(
        saveInstanceDataSubmit(
          company,
          division,
          types,
          task_id,
          report_number,
          div_compliance_id,
          values,
          dataType
        )
      );
    }
  };

export const submitInstanceData =
  (
    company,
    division,
    types,
    task_id,
    report_number,
    div_compliance_id,
    dataType
  ) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/submit-div-instance",
      data: {
        company,
        division,
        types,
        task_id,
        report_number,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "PASS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
          dispatch(
            getInstanceDetails(company, division, types, task_id, report_number)
          );
          if (dataType === "event") {
            dispatch(listEventInstance(company, division, div_compliance_id));
          } else if (dataType === "time") {
            dispatch(listTimeInstance(company, division, div_compliance_id));
          }
          dispatch(listApprovaltask(company, division));
          dispatch(listTaskData(company, division));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const saveRemarkInstanceData =
  (company, division, types, task_id, report_number, remarks) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/save-remarks",
      data: {
        company,
        division,
        types,
        task_id,
        report_number,
        remarks,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            getInstanceDetails(company, division, types, task_id, report_number)
          );
          dispatch(listApprovaltask(company, division));
          dispatch(listTaskData(company, division));
          dispatch(listEventTask(company, division));
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "PASS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const uploadInstanceData =
  (
    company,
    division,
    category,
    is_company_level,
    cpn_no,
    data,
    task_id,
    report_number,
    types,
    isSubmit = null
  ) =>
  async (dispatch) => {
    dispatch({
      type: UPLOADING_FILES_MY_COMPLIANCE,
      payload: { message: "Uploading files please wait...", isUploading: true },
    });
    const config = {
      "Content-Type": "application/json",
    };
    let formdata = new FormData();
    formdata.append("file", data[data.length - 1]);
    formdata.append("category", category);
    formdata.append("is_company_level", is_company_level);
    formdata.append("cpn_no", cpn_no);
    formdata.append("company", company);
    formdata.append("division", division);
    formdata.append("report_number", report_number);
    const body = formdata;
    await axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/upload-compliance",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            mapInstanceFiles(
              company,
              division,
              types,
              task_id,
              report_number,
              response.data.data,
              response.data.file_id,
              data.length === 1,
              isSubmit
            )
          );
          if (data.length === 1) {
            return;
          } else {
            data.pop();
            dispatch(
              uploadInstanceData(
                company,
                division,
                category,
                is_company_level,
                cpn_no,
                data,
                task_id,
                report_number,
                types,
                isSubmit
              )
            );
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

export const mapInstanceFiles =
  (
    company,
    division,
    types,
    task_id,
    report_number,
    file,
    file_id,
    flag = false,
    isSubmit
  ) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "operations/mapp-div-instance-files",
      data: {
        company,
        division,
        types,
        task_id,
        report_number,
        file,
        file_id,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (flag) {
            if (isSubmit !== null) {
              await dispatch(
                saveInstanceDataSubmit(
                  isSubmit.company,
                  isSubmit.division,
                  isSubmit.types,
                  isSubmit.task_id,
                  isSubmit.report_number,
                  isSubmit.div_compliance_id,
                  isSubmit.values,
                  isSubmit.dataType
                )
              );
            } else {
              await dispatch({
                type: SET_ALERT_FOR_MY_COMPLIANCE,
                payload: {
                  isAlert: true,
                  alertType: "PASS",
                  alertMessage: response.data.message,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: RESET_ALERT_FOR_MY_COMPLIANCE,
                  payload: {
                    isAlert: false,
                    alertType: null,
                    alertMessage: null,
                  },
                });
              }, 500);
              dispatch({
                type: UPLOADING_FILES_MY_COMPLIANCE,
                payload: {
                  message: null,
                  isUploading: false,
                },
              });
              dispatch(
                getInstanceDetails(
                  company,
                  division,
                  types,
                  task_id,
                  report_number
                )
              );
            }
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          dispatch({
            type: UPLOADING_FILES_MY_COMPLIANCE,
            payload: {
              message: null,
              isUploading: false,
            },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILES_MY_COMPLIANCE,
          payload: {
            message: null,
            isUploading: false,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      });
  };

let listApprovaltaskAbort = null;
export const listApprovaltask = (company, division) => async (dispatch) => {
  if (listApprovaltaskAbort !== null) {
    listApprovaltaskAbort.cancel("New Call made");
    listApprovaltaskAbort = null;
  }
  listApprovaltaskAbort = axios.CancelToken.source();
  await dispatch({ type: LIST_APPROVAL_LOADING, payload: true });
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "operations/list-instance-for-approval",
    data: {
      company,
      division,
    },
    headers: config,
    cancelToken: listApprovaltaskAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        var flag = true;
        if (response.data.data.length > 0) {
          var count = 0;
          response.data.data.forEach(
            (item) => item.role === "OWNER" && count++
          );
          if (count === response.data.data.length) {
            flag = false;
          }
          await dispatch({ type: SHOW_APPROVAL_TAB, payload: flag });
        } else {
          await dispatch({ type: SHOW_APPROVAL_TAB, payload: flag });
        }
        await dispatch({ type: LIST_APPROVAL_LOADING, payload: false });
        dispatch({
          type: LIST_INSTANCE_FOR_APPROVAL,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({ type: LIST_APPROVAL_LOADING, payload: false });
        dispatch({ type: LIST_INSTANCE_FOR_APPROVAL, payload: [] });
      } else {
        await dispatch({ type: LIST_APPROVAL_LOADING, payload: false });
        dispatch({ type: LIST_INSTANCE_FOR_APPROVAL, payload: [] });
      }
    })
    .catch((error) => {
      dispatch({ type: LIST_INSTANCE_FOR_APPROVAL, payload: [] });
    });
};

let listEventTaskAbort = null;
export const listEventTask = (company, division) => async (dispatch) => {
  if (listEventTaskAbort !== null) {
    listEventTaskAbort.cancel("New Call made");
    listEventTaskAbort = null;
  }
  listEventTaskAbort = axios.CancelToken.source();
  await dispatch({ type: LIST_EVENTS_LOADING, payload: true });
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "operations/list-event-instances-by-user",
    data: {
      company,
      division,
    },
    headers: config,
    cancelToken: listEventTaskAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({ type: LIST_EVENTS_LOADING, payload: false });
        dispatch({
          type: LIST_INSTANCE_FOR_EVENTS,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({ type: LIST_EVENTS_LOADING, payload: false });
        dispatch({ type: LIST_INSTANCE_FOR_EVENTS, payload: [] });
      } else {
        await dispatch({ type: LIST_EVENTS_LOADING, payload: false });
        dispatch({ type: LIST_INSTANCE_FOR_EVENTS, payload: [] });
      }
    })
    .catch((error) => {
      dispatch({ type: LIST_INSTANCE_FOR_EVENTS, payload: [] });
    });
};

let listTaskDataAbort = null;
export const listTaskData = (company, division) => async (dispatch) => {
  if (listTaskDataAbort !== null) {
    listTaskDataAbort.cancel("New Call made");
    listTaskDataAbort = null;
  }
  listTaskDataAbort = axios.CancelToken.source();
  await dispatch({ type: LIST_TASKS_LOADING, payload: true });
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "operations/list-instance-compliances",
    data: {
      company,
      division,
    },
    headers: config,
    cancelToken: listTaskDataAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({ type: LIST_TASKS_LOADING, payload: false });
        dispatch({
          type: LIST_TASK_INSTANCE_DATA,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({ type: LIST_TASKS_LOADING, payload: false });
        dispatch({ type: LIST_TASK_INSTANCE_DATA, payload: [] });
      } else {
        await dispatch({ type: LIST_TASKS_LOADING, payload: false });
        dispatch({ type: LIST_TASK_INSTANCE_DATA, payload: [] });
      }
    })
    .catch((error) => {
      dispatch({ type: LIST_TASK_INSTANCE_DATA, payload: [] });
    });
};

export const approvalMultiInstance = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "operations/div-instance-approve",
    data: values,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(listApprovaltask(values.company, values.division));
        dispatch(listTaskData(values.company, values.division));
        dispatch(listEventTask(values.company, values.division));
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "PASS",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        await dispatch({
          type: SET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: RESET_ALERT_FOR_MY_COMPLIANCE,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

let listDashboardDataAbort = null;
export const listDashboardData =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listDashboardDataAbort !== null) {
      listDashboardDataAbort.cancel("New Call made");
      listDashboardDataAbort = null;
    }
    listDashboardDataAbort = axios.CancelToken.source();
    sessionStorage.removeItem("DashboardDataTrigger");
    // if (division === null) {
    //   await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: false });
    //   dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
    //   return;
    // }
    await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-instances-metrics",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listDashboardDataAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          sessionStorage.setItem("DashboardDataTrigger", 1);
          dispatch(
            getProgressDashboard(
              company,
              "Metrics_graph",
              response.data.tracking_id
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: false });
          dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
        } else {
          await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: false });
          dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
      });
  };

let getProgressDashboardAbort = null;
let getProgressDashboardInterval = null;
export const getProgressDashboard =
  (company, task_type, tracking_id) => async (dispatch) => {
    if (getProgressDashboardAbort !== null) {
      getProgressDashboardAbort.cancel("New Call made");
      clearInterval(getProgressDashboardInterval);
      getProgressDashboardAbort = null;
    }
    getProgressDashboardAbort = axios.CancelToken.source();
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/get-task-status",
      data: {
        company,
        task_type,
        tracking_id,
      },
      headers: config,
      cancelToken: getProgressDashboardAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            await dispatch({
              type: LIST_DASHBOARD_DATA_LOADING,
              payload: false,
            });
            if (response.data.isError) {
              if (response.data.hasOwnProperty("url")) {
                if (response.data.url !== "") {
                  let req = new XMLHttpRequest();
                  req.open("GET", BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    "Authorization",
                    sessionStorage.getItem("token")
                  );
                  req.responseType = "blob";
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "errorlog.txt";
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: LIST_DASHBOARD_DATA,
                payload: [],
              });
            } else {
              var dataz = response.data.data;
              if (dataz.length > 0) {
                dataz[0].division_totals = response.data.division_totals;
              }
              dispatch({
                type: LIST_DASHBOARD_DATA,
                payload: dataz,
              });
            }
            await dispatch({
              type: LIST_DASHBOARD_DATA_LOADING,
              payload: false,
            });
          } else {
            if (sessionStorage.getItem("DashboardDataTrigger")) {
              getProgressDashboardInterval = setTimeout(() => {
                dispatch(getProgressDashboard(company, task_type, tracking_id));
              }, 2000);
            }
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: false });
          dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
        } else {
          await dispatch({ type: LIST_DASHBOARD_DATA_LOADING, payload: false });
          dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: LIST_DASHBOARD_DATA, payload: [] });
      });
  };

var getProgress2 = null;

let listCertificateDataAbort = null;
export const listCertificateData =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCertificateDataAbort !== null) {
      listCertificateDataAbort.cancel("New Call made");
      listCertificateDataAbort = null;
    }
    listCertificateDataAbort = axios.CancelToken.source();
    await dispatch({ type: LIST_CERTIFCATE_DATA_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/list-instance-for-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCertificateDataAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          dispatch({
            type: LIST_CERTIFCATE_DATA,
            payload: [response.data.data, response.data.div_details],
          });
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          dispatch({ type: LIST_CERTIFCATE_DATA, payload: [] });
        } else {
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          dispatch({ type: LIST_CERTIFCATE_DATA, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: LIST_CERTIFCATE_DATA, payload: [] });
      });
  };

export const getReason =
  (company, division, types, task_id, report_number) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-reasons",
      data: {
        company,
        division,
        types,
        task_id,
        report_number,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: GET_REASONS,
            payload: response.data.data,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({ type: GET_REASONS, payload: [] });
        } else {
          dispatch({ type: GET_REASONS, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_REASONS, payload: [] });
      });
  };
export const saveReason =
  (company, division, task_id, report_number, reasons, types) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/save-reasons",
      data: {
        company,
        division,
        reasons,
        task_id,
        report_number,
        types,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "SUCCESS",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 5000);
          dispatch({ type: GET_REASONS, payload: [] });
        } else {
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 5000);
          dispatch({ type: GET_REASONS, payload: [] });
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 5000);
        dispatch({ type: GET_REASONS, payload: [] });
      });
  };

export const downloadCertificate =
  (
    company,
    division,
    role_name,
    report_no,
    task_ids,
    end_date,
    start_date,
    start_year,
    end_year
  ) =>
  async (dispatch) => {
    await dispatch({ type: LIST_CERTIFCATE_DATA_LOADING, payload: true });
    await dispatch({
      type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
      payload: { loading: true, message: "Downloading..." },
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/download-certificates",
      data: {
        company,
        division,
        role_name,
        report_no,
        task_ids,
        end_date,
        start_date,
        start_year,
        end_year,
        default: report_no.length === 0 && task_ids.length === 0,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            getProgressCertificate(
              company,
              "download_certificate",
              response.data.tracking_id
            )
          );
          // getProgress2 = setInterval(() => {
          //   dispatch(
          //     getProgressCertificate(
          //       company,
          //       'download_certificate',
          //       tracking_id2
          //     )
          //   );
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          await dispatch({
            type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
        } else {
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          await dispatch({
            type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          await dispatch({
            type: SET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_ALERT_FOR_MY_COMPLIANCE,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_ALERT_FOR_MY_COMPLIANCE,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
        dispatch({ type: LIST_CERTIFCATE_DATA_LOADING, payload: false });
      });
  };
export const getProgressCertificate =
  (company, task_type, tracking_id) => async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({
      type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
      payload: { loading: true, message: "Downloading please wait..." },
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/get-task-status",
      data: {
        company,
        task_type,
        tracking_id,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            clearInterval(getProgress2);
            await dispatch({
              type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: false, message: "" },
            });
            await dispatch({
              type: LIST_CERTIFCATE_DATA_LOADING,
              payload: false,
            });
            if (!response.data.isError) {
              var req = new XMLHttpRequest();
              req.open(
                "GET",
                BACKEND_URL_2 + response.data.download_token,
                true
              );
              req.setRequestHeader(
                "Authorization",
                sessionStorage.getItem("token")
              );
              req.responseType = "blob";
              req.onload = function (event) {
                var blob = req.response;
                // var fileName = 'certificates.xlxs'; //if you have the fileName header available
                var link = document.createElement("a");
                link.download = "certificate.xlsx";
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                // link.download = fileName;
                link.click();
              };
              req.send();
            }
          } else {
            setTimeout(() => {
              dispatch(
                getProgressCertificate(
                  company,
                  "download_certificate",
                  tracking_id
                )
              );
            }, 2000);
            await dispatch({
              type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress2);
        } else if (response.data.statuscode === 500) {
          clearInterval(getProgress2);
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          await dispatch({
            type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
        } else {
          clearInterval(getProgress2);
          await dispatch({
            type: LIST_CERTIFCATE_DATA_LOADING,
            payload: false,
          });
          await dispatch({
            type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
        }
      })
      .catch((error) => {
        clearInterval(getProgress2);
        dispatch({
          type: LIST_CERTIFCATE_DATA_LOADING,
          payload: false,
        });
        dispatch({
          type: SET_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
      });
  };

let listCompliance1Abort = null;
export const listCompliance1 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance1Abort !== null) {
      listCompliance1Abort.cancel("New Call made");
      listCompliance1Abort = null;
    }
    listCompliance1Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_COMPLIANCE_STATUS_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-compliances-status-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance1Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_COMPLIANCE_STATUS_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_COMPLIANCE_STATUS,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_COMPLIANCE_STATUS_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_COMPLIANCE_STATUS_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_COMPLIANCE_STATUS, payload: [] });
        } else {
          await dispatch({
            type: REPORT_COMPLIANCE_STATUS_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_COMPLIANCE_STATUS, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_COMPLIANCE_STATUS, payload: [] });
      });
  };

let listCompliance2Abort = null;
export const listCompliance2 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance2Abort !== null) {
      listCompliance2Abort.cancel("New Call made");
      listCompliance2Abort = null;
    }
    listCompliance2Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_DELAYED_COMPLIANCE_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-delayed-compliances-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance2Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_DELAYED_COMPLIANCE_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_DELAYED_COMPLIANCE,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_DELAYED_COMPLIANCE_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_DELAYED_COMPLIANCE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_DELAYED_COMPLIANCE, payload: [] });
        } else {
          await dispatch({
            type: REPORT_DELAYED_COMPLIANCE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_DELAYED_COMPLIANCE, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_DELAYED_COMPLIANCE, payload: [] });
      });
  };

let listCompliance3Abort = null;
export const listCompliance3 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance3Abort !== null) {
      listCompliance3Abort.cancel("New Call made");
      listCompliance3Abort = null;
    }
    listCompliance3Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_EVENT_CHECKLIST_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/event-checklist-compliance-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance3Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_EVENT_CHECKLIST_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_EVENT_CHECKLIST,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_EVENT_CHECKLIST_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_EVENT_CHECKLIST_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_EVENT_CHECKLIST, payload: [] });
        } else {
          await dispatch({
            type: REPORT_EVENT_CHECKLIST_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_EVENT_CHECKLIST, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_EVENT_CHECKLIST, payload: [] });
      });
  };

let listCompliance4Abort = null;
export const listCompliance4 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance4Abort !== null) {
      listCompliance4Abort.cancel("New Call made");
      listCompliance4Abort = null;
    }
    listCompliance4Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_EVENT_OCCURANCE_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-event-occurrence-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance4Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_EVENT_OCCURANCE_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_EVENT_OCCURANCE,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_EVENT_OCCURANCE_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_EVENT_OCCURANCE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_EVENT_OCCURANCE, payload: [] });
        } else {
          await dispatch({
            type: REPORT_EVENT_OCCURANCE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_EVENT_OCCURANCE, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_EVENT_OCCURANCE, payload: [] });
      });
  };

let listCompliance5Abort = null;
export const listCompliance5 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance5Abort !== null) {
      listCompliance5Abort.cancel("New Call made");
      listCompliance5Abort = null;
    }
    listCompliance5Abort = axios.CancelToken.source();
    await dispatch({
      type: REPORT_CHECKER_VERIFICATION_LOADING,
      payload: true,
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-pending-checkers-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance5Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_CHECKER_VERIFICATION_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_CHECKER_VERIFICATION,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_CHECKER_VERIFICATION_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_CHECKER_VERIFICATION_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_CHECKER_VERIFICATION, payload: [] });
        } else {
          await dispatch({
            type: REPORT_CHECKER_VERIFICATION_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_CHECKER_VERIFICATION, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_CHECKER_VERIFICATION, payload: [] });
      });
  };

let listCompliance6Abort = null;
export const listCompliance6 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance6Abort !== null) {
      listCompliance6Abort.cancel("New Call made");
      listCompliance6Abort = null;
    }
    listCompliance6Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_ACTION_PLAN_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-compliances-status-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance6Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_ACTION_PLAN_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_ACTION_PLAN,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_ACTION_PLAN_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_ACTION_PLAN_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_ACTION_PLAN, payload: [] });
        } else {
          await dispatch({
            type: REPORT_ACTION_PLAN_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_ACTION_PLAN, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_ACTION_PLAN, payload: [] });
      });
  };

let listCompliance7Abort = null;
export const listCompliance7 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance7Abort !== null) {
      listCompliance7Abort.cancel("New Call made");
      listCompliance7Abort = null;
    }
    listCompliance7Abort = axios.CancelToken.source();
    await dispatch({
      type: REPORT_CERTIFICATE_GENERATION_LOADING,
      payload: true,
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-certificate-generation-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance7Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_CERTIFICATE_GENERATION_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_CERTIFICATE_GENERATION,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_CERTIFICATE_GENERATION_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_CERTIFICATE_GENERATION_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_CERTIFICATE_GENERATION, payload: [] });
        } else {
          await dispatch({
            type: REPORT_CERTIFICATE_GENERATION_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_CERTIFICATE_GENERATION, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_CERTIFICATE_GENERATION, payload: [] });
      });
  };

let listCompliance8Abort = null;
export const listCompliance8 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance8Abort !== null) {
      listCompliance8Abort.cancel("New Call made");
      listCompliance8Abort = null;
    }
    listCompliance8Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_COMPLIANCE_COUNT_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/owner-wise-compliance-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance8Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_COMPLIANCE_COUNT_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_COMPLIANCE_COUNT,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_COMPLIANCE_COUNT_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_COMPLIANCE_COUNT_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_COMPLIANCE_COUNT, payload: [] });
        } else {
          await dispatch({
            type: REPORT_COMPLIANCE_COUNT_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_COMPLIANCE_COUNT, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_COMPLIANCE_COUNT, payload: [] });
      });
  };

let listCompliance9Abort = null;
export const listCompliance9 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance9Abort !== null) {
      listCompliance9Abort.cancel("New Call made");
      listCompliance9Abort = null;
    }
    listCompliance9Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_USER_LOGIN_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/user-login-reports",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance9Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_USER_LOGIN_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_USER_LOGIN,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_USER_LOGIN_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_USER_LOGIN_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_USER_LOGIN, payload: [] });
        } else {
          await dispatch({
            type: REPORT_USER_LOGIN_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_USER_LOGIN, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_USER_LOGIN, payload: [] });
      });
  };

let listCompliance10Abort = null;
export const listCompliance10 =
  (company, division, start_date, end_date) => async (dispatch) => {
    if (listCompliance10Abort !== null) {
      listCompliance10Abort.cancel("New Call made");
      listCompliance10Abort = null;
    }
    listCompliance10Abort = axios.CancelToken.source();
    await dispatch({ type: REPORT_NOT_APPLICABLE_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-unrelated-compliance-report",
      data: {
        company,
        division,
        start_date,
        end_date,
      },
      headers: config,
      cancelToken: listCompliance10Abort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch({
            type: REPORT_NOT_APPLICABLE_LOADING,
            payload: false,
          });
          dispatch({
            type: REPORT_NOT_APPLICABLE,
            payload: response.data.data,
          });
          await dispatch({
            type: REPORT_NOT_APPLICABLE_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({
            type: REPORT_NOT_APPLICABLE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_NOT_APPLICABLE, payload: [] });
        } else {
          await dispatch({
            type: REPORT_NOT_APPLICABLE_LOADING,
            payload: false,
          });
          dispatch({ type: REPORT_NOT_APPLICABLE, payload: [] });
        }
      })
      .catch((error) => {
        dispatch({ type: REPORT_NOT_APPLICABLE, payload: [] });
      });
  };

export const downloadFile = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "metrics/download-certificate-logs",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        var req = new XMLHttpRequest();
        req.open("GET", BACKEND_URL_2 + response.data.url, true);
        req.setRequestHeader("Authorization", sessionStorage.getItem("token"));
        req.responseType = "blob";
        req.onload = function (event) {
          var blob = req.response;
          // var fileName = 'certificate'; //if you have the fileName header available
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          // link.download = fileName;
          link.click();
        };
        req.send();
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

export const downloadLogFile = (values) => async (dispatch) => {
  await dispatch({
    type: SET_DOWNLOAD_ACTIVITY_LOG,
    payload: { loading: true, message: "Downloading please wait..." },
  });
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/download-audit-logs",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(
          getProgressLogFile(
            result.company,
            "DOWNLOAD_AUDIT_LOGS",
            response.data.tracking_id
          )
        );
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: SET_DOWNLOAD_ACTIVITY_LOG,
          payload: { loading: false, message: "Downloading please wait..." },
        });
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        await dispatch({
          type: SET_DOWNLOAD_ACTIVITY_LOG,
          payload: { loading: false, message: "Downloading please wait..." },
        });
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_DOWNLOAD_ACTIVITY_LOG,
        payload: { loading: false, message: "Downloading please wait..." },
      });
      setTimeout(() => {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

export const getProgressLogFile =
  (company, task_type, tracking_id) => async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({
      type: SET_DOWNLOAD_ACTIVITY_LOG,
      payload: { loading: true, message: "Downloading please wait..." },
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/get-task-status",
      data: {
        company,
        task_type,
        tracking_id,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            clearInterval(getProgress2);
            await dispatch({
              type: SET_DOWNLOAD_ACTIVITY_LOG,
              payload: { loading: false, message: "" },
            });
            if (!response.data.isError) {
              var req = new XMLHttpRequest();
              req.open("GET", BACKEND_URL_2 + response.data.data, true);
              req.setRequestHeader(
                "Authorization",
                sessionStorage.getItem("token")
              );
              req.responseType = "blob";
              req.onload = function (event) {
                var blob = req.response;
                // var fileName = 'certificates.xlxs'; //if you have the fileName header available
                var link = document.createElement("a");
                link.download = "ActivityLogs.xlsx";
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                // link.download = fileName;
                link.click();
              };
              req.send();
            } else {
              dispatch({
                type: GLOBAL_ALERT_SYSTEM,
                payload: {
                  isAlert: true,
                  alertType: "FAIL",
                  alertMessage: response.data.message,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: GLOBAL_ALERT_SYSTEM,
                  payload: {
                    isAlert: false,
                    alertType: null,
                    alertMessage: null,
                  },
                });
              }, 500);
            }
          } else {
            setTimeout(() => {
              dispatch(
                getProgressLogFile(company, "DOWNLOAD_AUDIT_LOGS", tracking_id)
              );
            }, 2000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress2);
        } else if (response.data.statuscode === 500) {
          clearInterval(getProgress2);
          await dispatch({
            type: SET_DOWNLOAD_ACTIVITY_LOG,
            payload: { loading: false, message: "Downloading please wait..." },
          });
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: GLOBAL_ALERT_SYSTEM,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        } else {
          clearInterval(getProgress2);
          await dispatch({
            type: SET_DOWNLOAD_ACTIVITY_LOG,
            payload: { loading: false, message: "Downloading please wait..." },
          });
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: true,
              alertType: "FAIL",
              alertMessage: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: GLOBAL_ALERT_SYSTEM,
              payload: {
                isAlert: false,
                alertType: null,
                alertMessage: null,
              },
            });
          }, 500);
        }
      })
      .catch((error) => {
        clearInterval(getProgress2);
        dispatch({
          type: SET_DOWNLOAD_ACTIVITY_LOG,
          payload: { loading: false, message: "Downloading please wait..." },
        });
        dispatch({
          type: SET_DOWNLOAD_ACTIVITY_LOG,
          payload: { loading: false, message: "" },
        });
      });
  };

let listComplianceStatusDashboard = null;
export const listComplianceDataDashboard =
  (company, division, start_date, end_date, category) => async (dispatch) => {
    if (listComplianceStatusDashboard !== null) {
      listComplianceStatusDashboard.cancel("New Call made");
      listComplianceStatusDashboard = null;
    }
    listComplianceStatusDashboard = axios.CancelToken.source();
    dispatch({
      type: COMPLIANCE_DATA_DASHBOARD,
      payload: { data: [], loading: true },
    });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "metrics/get-dashboaard-details",
      data: {
        company,
        division,
        start_date,
        end_date,
        category,
      },
      headers: config,
      cancelToken: listComplianceStatusDashboard.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: COMPLIANCE_DATA_DASHBOARD,
            payload: { data: response.data.data, loading: false },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: COMPLIANCE_DATA_DASHBOARD,
            payload: { data: [], loading: false },
          });
        } else {
          dispatch({
            type: COMPLIANCE_DATA_DASHBOARD,
            payload: { data: [], loading: false },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: COMPLIANCE_DATA_DASHBOARD,
          payload: { data: [], loading: false },
        });
      });
  };
