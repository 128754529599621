import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout, Select, Tooltip, Modal, Col, Row } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
// import NavNotification from './NavNotification';
import NavProfile from "./NavProfile";

import {
  toggleCollapsedNav,
  onMobileNavToggle,
} from "../../redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "../../constants/ThemeConstant";
import utils from "../../utils";
import { Link, useHistory } from "react-router-dom";
import { listProjectsTop } from "../../apis/dashboard/project";
import {
  getLoggedinRole,
  listApprovaltask,
  listComplianceForUser,
  listEventTask,
  listTaskData,
} from "../../apis/dashboard/userLevelCompliance";
import store from "../../redux/store";
import {
  TRIGGER_API,
  TRIGGER_API_2,
  R_TRIGGER_API_1,
  R_TRIGGER_API_2,
  R_TRIGGER_API_3,
  R_TRIGGER_API_4,
  R_TRIGGER_API_5,
  R_TRIGGER_API_6,
  R_TRIGGER_API_7,
  R_TRIGGER_API_8,
  R_TRIGGER_API_9,
  R_TRIGGER_API_10,
  SET_DIVISION_ROLE,
  SET_COMPANY_ROLE,
  TRIGGER_DASHBOARD_API,
} from "../../actions/types";
const { Option } = Select;
const { Header } = Layout;
var firstTime = true;
var listOFlink = [
  "/app/company-management",
  "/app/compliance-admin",
  "/app/user-management",
  "/app/division-management",
  "/app/company-compliance",
  "/app/division-compliance",
  "/app/compliance-mapping",
  "/app/compliance-detailed-view",
];

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    companyLoading,
    companyList,
    listProjectsTop,
    projectList,
    projectLoading,
    listComplianceForUser,
    listApprovaltask,
    getLoggedinRole,
    listEventTask,
    listTaskData,
    userRole,
  } = props;

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  const [visible, setvisible] = useState(false);
  const [listCompany, setListCompany] = useState({});
  const [listDivision, setlistDivision] = useState(null);
  const [divisionRole, setdivisionRole] = useState(null);
  const [companyRole, setcompanyRole] = useState(null);

  const [listCompanyData, setListCompanyData] = useState([]);
  const [listDivisionData, setListDivisionData] = useState([]);

  useEffect(() => {
    if (!companyLoading && companyList.length > 0) {
      var result = [];
      companyList.forEach((e) => {
        if (e.status) result.push({ key: e.id, value: e.id, label: e.name });
      });
      setListCompanyData(result);
      if (sessionStorage.getItem("company_selected_top") === null) {
        sessionStorage.setItem("company_selected_top", result[0].value);
        sessionStorage.setItem("company_selected_name_top", result[0].label);
        sessionStorage.setItem("company_selected_top_temp", result[0].value);
        setListCompany({
          key: result[0].value,
          label: result[0].label,
          value: result[0].value,
        });
        store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
        listProjectsTop(parseInt(result[0].value));
        if (firstTime) {
          setcompanyRole(result[0].role);
        }
      } else {
        var data = result.filter(
          (item) =>
            parseInt(item.value) ===
            parseInt(sessionStorage.getItem("company_selected_top"))
        );
        if (data.length > 0) {
          setListCompany({
            key: parseInt(sessionStorage.getItem("company_selected_top")),
            value: parseInt(sessionStorage.getItem("company_selected_top")),
            label: data[0].label,
          });
          sessionStorage.setItem("company_selected_name_top", data[0].label);
          if (firstTime) {
            setcompanyRole(data[0].role);
          }
        } else {
          sessionStorage.setItem("company_selected_top", result[0].value);
          sessionStorage.setItem("company_selected_name_top", result[0].label);
          sessionStorage.setItem("company_selected_top_temp", result[0].value);
          setListCompany({
            key: parseInt(result[0].value),
            value: parseInt(result[0].value),
            label: result[0].label,
          });
          sessionStorage.setItem("company_selected_name_top", result[0].label);
          if (firstTime) {
            setcompanyRole(result[0].role);
          }
        }
        store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
        listProjectsTop(
          parseInt(sessionStorage.getItem("company_selected_top"))
        );
      }
    }
  }, [companyList, companyLoading, listProjectsTop]);

  useEffect(() => {
    store.dispatch({ type: SET_DIVISION_ROLE, payload: divisionRole });
  }, [divisionRole]);
  useEffect(() => {
    store.dispatch({ type: SET_COMPANY_ROLE, payload: companyRole });
  }, [companyRole]);

  useEffect(() => {
    if (!projectLoading && projectList.length > 0) {
      var result = [];
      var resultSet = [];
      projectList.forEach((e) => {
        if (e.isActive) {
          result.push({
            key: e.division,
            value: e.division,
            label: e.div_data.name,
          });
          resultSet.push(e);
        }
      });
      setListDivisionData(result);
      if (
        sessionStorage.getItem("division_selected_top") === null &&
        resultSet.length > 0
      ) {
        sessionStorage.setItem("division_selected_top", resultSet[0].division);
        sessionStorage.setItem(
          "division_selected_UUID_top",
          resultSet[0].div_data.project_id
        );
        sessionStorage.setItem(
          "division_selected_name_top",
          resultSet[0].div_data.name
        );
        if (firstTime) {
          firstTime = false;
          getLoggedinRole(
            parseInt(sessionStorage.getItem("company_selected_top")),
            resultSet[0].division
          );
          listComplianceForUser(
            parseInt(sessionStorage.getItem("company_selected_top")),
            resultSet[0].division
          );
          listApprovaltask(
            parseInt(sessionStorage.getItem("company_selected_top")),
            resultSet[0].division
          );
          listEventTask(
            parseInt(sessionStorage.getItem("company_selected_top")),
            resultSet[0].division
          );
          listTaskData(
            parseInt(sessionStorage.getItem("company_selected_top")),
            resultSet[0].division
          );
          setdivisionRole(resultSet[0].div_data.user_role);
          store.dispatch({ type: TRIGGER_API, payload: true });
          store.dispatch({ type: TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_1, payload: true });
          store.dispatch({ type: R_TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_3, payload: true });
          store.dispatch({ type: R_TRIGGER_API_4, payload: true });
          store.dispatch({ type: R_TRIGGER_API_5, payload: true });
          store.dispatch({ type: R_TRIGGER_API_6, payload: true });
          store.dispatch({ type: R_TRIGGER_API_7, payload: true });
          store.dispatch({ type: R_TRIGGER_API_8, payload: true });
          store.dispatch({ type: R_TRIGGER_API_9, payload: true });
          store.dispatch({ type: R_TRIGGER_API_10, payload: true });
        }
        setlistDivision({
          key: resultSet[0].division,
          label: resultSet[0].div_data.name,
          value: resultSet[0].division,
        });
      } else {
        if (resultSet.length > 0) {
          var flag = false;
          resultSet.forEach((ele, index) => {
            if (
              ele.division ===
              parseInt(sessionStorage.getItem("division_selected_top"))
            ) {
              flag = true;
            }
          });
          if (flag) {
            setlistDivision({
              key: sessionStorage.getItem("division_selected_UUID_top"),
              label: sessionStorage.getItem("division_selected_name_top"),
              value: sessionStorage.getItem("division_selected_top"),
            });
            if (firstTime) {
              firstTime = false;
              getLoggedinRole(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              listComplianceForUser(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              listApprovaltask(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              listEventTask(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              listTaskData(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              resultSet.forEach((item) => {
                if (
                  item.division ===
                  parseInt(sessionStorage.getItem("division_selected_top"))
                ) {
                  setdivisionRole(item.div_data.user_role);
                }
              });
              setdivisionRole(resultSet[0].div_data.user_role);
              store.dispatch({ type: TRIGGER_API_2, payload: true });
              store.dispatch({ type: TRIGGER_API, payload: true });
              store.dispatch({ type: R_TRIGGER_API_1, payload: true });
              store.dispatch({ type: R_TRIGGER_API_2, payload: true });
              store.dispatch({ type: R_TRIGGER_API_3, payload: true });
              store.dispatch({ type: R_TRIGGER_API_4, payload: true });
              store.dispatch({ type: R_TRIGGER_API_5, payload: true });
              store.dispatch({ type: R_TRIGGER_API_6, payload: true });
              store.dispatch({ type: R_TRIGGER_API_7, payload: true });
              store.dispatch({ type: R_TRIGGER_API_8, payload: true });
              store.dispatch({ type: R_TRIGGER_API_9, payload: true });
              store.dispatch({ type: R_TRIGGER_API_10, payload: true });
            }
          } else {
            setlistDivision({
              key: resultSet[0].division,
              label: resultSet[0].div_data.name,
              value: resultSet[0].division,
            });
            if (firstTime) {
              firstTime = false;
              getLoggedinRole(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(sessionStorage.getItem("division_selected_top"))
              );
              listComplianceForUser(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(resultSet[0].division)
              );
              listApprovaltask(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(resultSet[0].division)
              );
              listEventTask(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(resultSet[0].division)
              );
              listTaskData(
                parseInt(sessionStorage.getItem("company_selected_top_temp")),
                parseInt(resultSet[0].division)
              );
              setdivisionRole(resultSet[0].div_data.user_role);
              store.dispatch({ type: TRIGGER_API, payload: true });
              store.dispatch({ type: TRIGGER_API_2, payload: true });
              store.dispatch({ type: R_TRIGGER_API_1, payload: true });
              store.dispatch({ type: R_TRIGGER_API_2, payload: true });
              store.dispatch({ type: R_TRIGGER_API_3, payload: true });
              store.dispatch({ type: R_TRIGGER_API_4, payload: true });
              store.dispatch({ type: R_TRIGGER_API_5, payload: true });
              store.dispatch({ type: R_TRIGGER_API_6, payload: true });
              store.dispatch({ type: R_TRIGGER_API_7, payload: true });
              store.dispatch({ type: R_TRIGGER_API_8, payload: true });
              store.dispatch({ type: R_TRIGGER_API_9, payload: true });
              store.dispatch({ type: R_TRIGGER_API_10, payload: true });
            }
          }
        }
      }
    }
  }, [
    projectList,
    projectLoading,
    listComplianceForUser,
    getLoggedinRole,
    listApprovaltask,
    listEventTask,
    listTaskData,
  ]);

  const [showNav, setshowNav] = useState(false);

  const history = useHistory();

  useEffect(() => {
    var flag = listOFlink.indexOf(window.location.pathname) > -1;
    setshowNav(!flag);
    return () => {
      var flag = listOFlink.indexOf(window.location.pathname) > -1;
      setshowNav(!flag);
    };
  }, []);

  history.listen((ele) => {
    var flag = listOFlink.indexOf(ele.pathname) > -1;
    setshowNav(!flag);
  });

  useEffect(() => {
    if (!props.isAuthenticated) {
      firstTime = true;
    }
  }, [props.isAuthenticated]);

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor, zIndex: "999" }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {userRole !== "COMPLIANCE-ADMIN" && showNav && (
                <li className="ant-menu-item ant-menu-item-only-child">
                  <Tooltip title="Select Division" placement="right">
                    <Link
                      to="#!"
                      onClick={(e) => {
                        if (
                          listCompanyData.length > 1 ||
                          listDivisionData.length > 1
                        ) {
                          setvisible(true);
                        }
                      }}
                      style={{ fontSize: "13px" }}
                    >
                      {listDivision !== null
                        ? listDivision.label
                        : "No division selected"}
                      <CaretDownOutlined style={{ fontSize: "13px" }} />
                    </Link>
                  </Tooltip>
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            {/* <NavNotification /> */}
            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
      <Modal
        title="Select Division"
        visible={visible}
        okText="Save"
        onOk={async (e) => {
          await sessionStorage.setItem(
            "company_selected_top",
            listCompany.value
          );
          await sessionStorage.setItem(
            "company_selected_name_top",
            listCompany.label
          );
          sessionStorage.removeItem("division_selected_top");
          sessionStorage.removeItem("division_selected_name_top");
          sessionStorage.removeItem("division_selected_UUID_top");
          if (listDivision !== null) {
            projectList.forEach((item) => {
              if (item.division === parseInt(listDivision.key)) {
                setdivisionRole(item.div_data.user_role);
              }
            });
            sessionStorage.setItem("division_selected_top", listDivision.value);
            sessionStorage.setItem(
              "division_selected_name_top",
              listDivision.label
            );
            sessionStorage.setItem(
              "division_selected_UUID_top",
              listDivision.key
            );
            listComplianceForUser(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
            getLoggedinRole(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
            listApprovaltask(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
            listEventTask(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
            listTaskData(
              parseInt(listCompany.value),
              parseInt(listDivision.value)
            );
          } else {
            listComplianceForUser(parseInt(listCompany.value), null);
            getLoggedinRole(parseInt(listCompany.value), null);
            listApprovaltask(parseInt(listCompany.value), null);
            listEventTask(parseInt(listCompany.value), null);
            listTaskData(parseInt(listCompany.value), null);
            setdivisionRole(null);
          }
          if (listCompany !== null) {
            companyList.forEach((item) => {
              if (item.id === parseInt(listCompany.value)) {
                setcompanyRole(item.role);
              }
            });
          } else {
            setcompanyRole(null);
          }
          store.dispatch({ type: TRIGGER_API, payload: true });
          store.dispatch({ type: TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_1, payload: true });
          store.dispatch({ type: R_TRIGGER_API_2, payload: true });
          store.dispatch({ type: R_TRIGGER_API_3, payload: true });
          store.dispatch({ type: R_TRIGGER_API_4, payload: true });
          store.dispatch({ type: R_TRIGGER_API_5, payload: true });
          store.dispatch({ type: R_TRIGGER_API_6, payload: true });
          store.dispatch({ type: R_TRIGGER_API_7, payload: true });
          store.dispatch({ type: R_TRIGGER_API_8, payload: true });
          store.dispatch({ type: R_TRIGGER_API_9, payload: true });
          store.dispatch({ type: R_TRIGGER_API_10, payload: true });
          setvisible(false);
          window.location.href = "/app/matrices/dashboard";
        }}
        onCancel={(e) => {
          setListCompany({
            key: parseInt(sessionStorage.getItem("company_selected_top")),
            value: parseInt(sessionStorage.getItem("company_selected_top")),
            label: sessionStorage.getItem("company_selected_name_top"),
          });
          store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
          listProjectsTop(
            parseInt(sessionStorage.getItem("company_selected_top"))
          );
          setvisible(false);
        }}
      >
        <Row gutter={16}>
          <Col span={12} className="pr-2">
            <Select
              showSearch
              labelInValue
              style={{ width: "100%" }}
              placeholder="Select Company"
              optionFilterProp="children"
              value={listCompany.hasOwnProperty("value") ? listCompany : null}
              onChange={async (value, key) => {
                setListCompany(value);
                setListDivisionData([]);
                sessionStorage.setItem(
                  "company_selected_top_temp",
                  value.value
                );
                setlistDivision(null);
                store.dispatch({ type: TRIGGER_DASHBOARD_API, payload: true });
                listProjectsTop(value.key);
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCompanyData.map((item, index) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12} className="pl-2">
            <Select
              showSearch
              labelInValue
              className="ml-2"
              style={{ width: "100%" }}
              placeholder="Select Division"
              optionFilterProp="children"
              value={listDivision}
              onChange={(value, key) => {
                setlistDivision(value);
                sessionStorage.setItem("division_selected_top", value.value);
                sessionStorage.setItem(
                  "division_selected_name_top",
                  value.label
                );
                sessionStorage.setItem("division_selected_UUID_top", value.key);
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDivisionData.map((item, index) => (
                <Option key={item.key} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </Header>
  );
};

const mapStateToProps = ({ theme, company, project, auth }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    isAuthenticated,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    isAuthenticated,
    companyLoading: company.loading,
    companyList: company.data,
    projectLoading: project.loading,
    projectList: project.topProjectList,
    userRole: auth.customeUserRole,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  listProjectsTop,
  onMobileNavToggle,
  listComplianceForUser,
  listApprovaltask,
  listEventTask,
  listTaskData,
  getLoggedinRole,
})(HeaderNav);
