import axios from 'axios';
import store from '../../redux/store';
import {
  BACKEND_URL,
  BACKEND_URL_2,
  CHECK_USER_EMAIL_INVALID,
  CHECK_USER_EMAIL_VALID,
  COMPLIANCE_ALERT_REMOVE,
  COMPLIANCE_ALERT_SET,
  COMPLIANCE_MAPPING_PARAMETER,
  CREATE_DIVISION_USER_FAILED,
  CREATE_DIVISION_USER_SUCCESS,
  LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING,
  LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED,
  LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS,
  LIST_COMPANY_COMPLIANCE_FAILED,
  LIST_COMPANY_COMPLIANCE_LOADING,
  LIST_COMPANY_COMPLIANCE_SUCCESS,
  LIST_DIVISION_COMPLIANCE_FAILED,
  LIST_DIVISION_COMPLIANCE_LOADING,
  LIST_DIVISION_COMPLIANCE_SUCCESS,
  REMOVE_COMPLIANCE_ADMIN_ALERT,
  RESET_IDLE_TIME,
  SET_BUILK_MESSAGE,
  SET_BULK_LOADER,
  SET_ISOPEN_DIVISION_USER,
  SET_LOGOUT_PROVISION,
  USER_DOWNLOAD_CERTIFICATE_PARAMETER,
} from '../../actions/types';
// import { editDivisionComplianceData } from './complianceMaster';
import { checkProgressDetails } from 'apis/login/user';

let listCompanyComplianceAbort = null;
export const listCompanyCompliance =
  (company, from_id = 0, to_id = 5000, query = '') =>
  async (dispatch) => {
    if (listCompanyComplianceAbort !== null) {
      listCompanyComplianceAbort.cancel('New Call made');
      listCompanyComplianceAbort = null;
    }
    listCompanyComplianceAbort = axios.CancelToken.source();
    dispatch({ type: LIST_COMPANY_COMPLIANCE_LOADING, payload: true });
    const config = {
      'Content-Type': 'application/json',
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/list-company-compliance',
      data: { company, from_id, to_id, query },
      headers: config,
      cancelToken: listCompanyComplianceAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          // dispatch(listAvailableCompanyCompliance(company));
          await dispatch({
            type: LIST_COMPANY_COMPLIANCE_SUCCESS,
            payload: {
              message: response.data.message,
              data: response.data.data,
              total: response.data.records,
            },
          });
          dispatch({ type: LIST_COMPANY_COMPLIANCE_LOADING, payload: false });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: LIST_COMPANY_COMPLIANCE_FAILED,
            payload: {
              message: response.data.message,
              data: [],
            },
          });
          await dispatch({
            type: LIST_COMPANY_COMPLIANCE_LOADING,
            payload: false,
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 4000);
        }
      })
      .catch((error) => {
        dispatch({
          type: LIST_COMPANY_COMPLIANCE_FAILED,
          payload: {
            message: 'Server Error',
            data: [],
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 8000);
      });
  };

export const listAvailableCompanyCompliance = (company) => async (dispatch) => {
  dispatch({ type: LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING, payload: true });

  const config = {
    'Content-Type': 'application/json',
  };

  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/list-available-compliance',
    data: { company },
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch({
          type: LIST_COMPANY_COMPLIANCE_AVAILABLE_SUCCESS,
          payload: {
            message: response.data.message,
            data: response.data.data,
          },
        });
        await dispatch({
          type: LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING,
          payload: false,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        await dispatch({
          type: LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED,
          payload: {
            message: response.data.message,
            data: [],
          },
        });
        await dispatch({
          type: LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING,
          payload: false,
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 4000);
      }
    })
    .catch((error) => {
      dispatch({
        type: LIST_COMPANY_COMPLIANCE_AVAILABLE_FAILED,
        payload: {
          message: 'Server Error',
          data: [],
        },
      });
      dispatch({
        type: LIST_COMPANY_AVAILABLE_COMPLIANCE_LOADING,
        payload: false,
      });
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 8000);
    });
};

export const mapCompanyCompliance = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/assign-company-compliance',
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          listCompanyCompliance(sessionStorage.getItem('compliance-company'))
        );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      dispatch({
        type: COMPLIANCE_ALERT_SET,
        payload: {
          isAlert: true,
          isError: true,
          message: 'Server Error',
        },
      });
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};

export const removemapCompanyCompliance = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/remove-company-compliance',
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          listCompanyCompliance(sessionStorage.getItem('compliance-company'))
        );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      dispatch({
        type: COMPLIANCE_ALERT_SET,
        payload: {
          isAlert: true,
          isError: true,
          message: 'Server Error',
        },
      });
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};

export const mapDivisionCompliance = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/assign-division-compliance',
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        // await dispatch(
        //   listCompanyCompliance(sessionStorage.getItem('company_selected'))
        // );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      dispatch({
        type: COMPLIANCE_ALERT_SET,
        payload: {
          isAlert: true,
          isError: true,
          message: 'Server Error',
        },
      });
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};
export const removemapDivisionCompliance = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/remove-division-compliance',
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          listDivisionCompliance(
            sessionStorage.getItem('company_selected'),
            sessionStorage.getItem('division_selected')
          )
        );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};

export const removeEscalator3 = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/delete-division-escalator3',
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          listDivisionCompliance(
            sessionStorage.getItem('company_selected'),
            sessionStorage.getItem('division_selected')
          )
        );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};

export const deactivateCompliance = (data) => async (dispatch) => {
  const config = {
    'Content-Type': 'application/json',
  };

  const body = data;
  await axios({
    method: 'POST',
    url: BACKEND_URL_2 + 'project-management/delete-compliance',
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          listCompanyCompliance(sessionStorage.getItem('company_selected'))
        );
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: false,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: 'Server Error',
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      } else {
        await dispatch({
          type: COMPLIANCE_ALERT_SET,
          payload: {
            isAlert: true,
            isError: true,
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 4000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: COMPLIANCE_ALERT_REMOVE,
        });
      }, 4000);
    });
};

let listDivisionComplianceAbort = null;
export const listDivisionCompliance =
  (company, division) => async (dispatch) => {
    if (listDivisionComplianceAbort !== null) {
      await listDivisionComplianceAbort.cancel('New Call made');
      listDivisionComplianceAbort = null;
    }
    listDivisionComplianceAbort = axios.CancelToken.source();
    dispatch({ type: LIST_DIVISION_COMPLIANCE_LOADING, payload: true });
    const config = {
      'Content-Type': 'application/json',
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/list-division-compliance',
      data: { company, division },
      headers: config,
      cancelToken: listDivisionComplianceAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({ type: LIST_DIVISION_COMPLIANCE_LOADING, payload: false });
          dispatch({
            type: LIST_DIVISION_COMPLIANCE_SUCCESS,
            payload: {
              message: response.data.message,
              data: response.data.data,
            },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({ type: LIST_DIVISION_COMPLIANCE_LOADING, payload: false });
          dispatch({
            type: LIST_DIVISION_COMPLIANCE_FAILED,
            payload: {
              message: response.data.message,
              data: [],
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 4000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 8000);
      });
  };

///assign user to compliance division
export const getRole =
  (
    role_name,
    email,
    first_name,
    last_name,
    user_id,
    role_type,
    result,
    reassign = false,
    flag = null
  ) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = { role_name };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/get-role',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            await createUserProjectRole(
              email,
              first_name,
              last_name,
              response.data.data.id,
              user_id,
              role_type,
              result,
              reassign,
              flag
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const createNewUser =
  (
    first_name,
    last_name,
    email,
    role_name,
    role_type,
    result,
    reassign,
    flag = null
  ) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const name = first_name + ' ' + last_name;
    const body = { first_name, last_name, name, email };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/register-user',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            checkUserByEmail2(
              email,
              role_name,
              role_type,
              result,
              reassign,
              flag
            )
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 400) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const checkUserByEmail2 =
  (email, role_name, role_type, result, reassign, flag) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = { email };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'access/list-user-byemail',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            getRole(
              role_name,
              response.data.data.email,
              response.data.data.first_name,
              response.data.data.last_name,
              response.data.data.id,
              role_type,
              result,
              reassign,
              flag
            )
          );
          dispatch({
            type: CHECK_USER_EMAIL_VALID,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
              data: response.data,
            },
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CHECK_USER_EMAIL_INVALID,
            payload: {
              message: response.data.message,
              type: 'COMPANY_MANAGEMENT',
            },
          });
        }
      })
      .catch((error) => {});
  };

export const createUserProjectRole =
  (
    email,
    first_name,
    last_name,
    user_role,
    user,
    role_type,
    result,
    reassign,
    flag
  ) =>
  async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company_id: store.getState().auth.data.company_id,
      project_id: sessionStorage.getItem('division_selected_UUID'),
      user_role,
      first_name,
      last_name,
      isUser: true,
      user_email: email,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL + 'project/create-project-user',
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(
            listDivisionRoles(user, role_type, result, reassign, flag)
          );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const listDivisionRoles =
  (user, role_name, result, reassign, flag) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + '/project-management/get-division-roles',
      data: {
        role_name,
        company: sessionStorage.getItem('company_selected'),
        division: sessionStorage.getItem('division_selected'),
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (role_name === 'OWNER') {
            dispatch(
              assignProjectOnwer(response.data.id, user, result, reassign, flag)
            );
          }
          if (role_name === 'ESCALATOR1') {
            dispatch(
              assignProjectEscalator1(
                response.data.id,
                user,
                result,
                reassign,
                flag
              )
            );
          }
          if (role_name === 'ESCALATOR2') {
            dispatch(
              assignProjectEscalator2(
                response.data.id,
                user,
                result,
                reassign,
                flag
              )
            );
          }
          if (role_name === 'ESCALATOR3') {
            dispatch(
              assignProjectEscalator3(
                response.data.id,
                user,
                result,
                reassign,
                flag
              )
            );
          }
          // dispatch(assignProjectUser(response.data.id, user));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const assignProjectOnwer =
  (role, user, company_compliance, reassign, flag) => async (dispatch) => {
    var url = 'assign-division-owner';
    if (flag !== null) {
      url = 'update-division-owner';
    }
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company: parseInt(sessionStorage.getItem('company_selected')),
      division: parseInt(sessionStorage.getItem('division_selected')),
      role,
      user,
      old_user: flag,
      company_compliance,
      new_user: user,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/' + url,
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_DIVISION_USER_SUCCESS,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          // if (reassign) {
          //   dispatch(
          //     editDivisionComplianceData({
          //       id: store.getState().compliance.editComplianceDataId,
          //     })
          //   );
          // }
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_DIVISION_USER,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
          // dispatch(
          //   listDivisionCompliance(
          //     sessionStorage.getItem('company_selected'),
          //     sessionStorage.getItem('division_selected')
          //   )
          // );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const assignProjectEscalator1 =
  (role, user, company_compliance, reassign, flag) => async (dispatch) => {
    var url = 'assign-division-escalator1';
    if (flag !== null) {
      url = 'update-division-escalator1';
    }
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company: parseInt(sessionStorage.getItem('company_selected')),
      division: parseInt(sessionStorage.getItem('division_selected')),
      role,
      user,
      old_user: flag,
      company_compliance,
      new_user: user,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/' + url,
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_DIVISION_USER_SUCCESS,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          // if (reassign) {
          //   dispatch(
          //     editDivisionComplianceData({
          //       id: store.getState().compliance.editComplianceDataId,
          //     })
          //   );
          // }
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_DIVISION_USER,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
          // dispatch(
          //   listDivisionCompliance(
          //     sessionStorage.getItem('company_selected'),
          //     sessionStorage.getItem('division_selected')
          //   )
          // );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const assignProjectEscalator2 =
  (role, user, company_compliance, reassign, flag) => async (dispatch) => {
    var url = 'assign-division-escalator2';
    if (flag !== null) {
      url = 'update-division-escalator2';
    }
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company: parseInt(sessionStorage.getItem('company_selected')),
      division: parseInt(sessionStorage.getItem('division_selected')),
      role,
      user,
      old_user: flag,
      company_compliance,
      new_user: user,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/' + url,
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_DIVISION_USER_SUCCESS,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          // if (reassign) {
          //   dispatch(
          //     editDivisionComplianceData({
          //       id: store.getState().compliance.editComplianceDataId,
          //     })
          //   );
          // }
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_DIVISION_USER,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
          // dispatch(
          //   listDivisionCompliance(
          //     sessionStorage.getItem('company_selected'),
          //     sessionStorage.getItem('division_selected')
          //   )
          // );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };

export const assignProjectEscalator3 =
  (role, user, company_compliance, reassign, flag) => async (dispatch) => {
    var url = 'assign-division-escalator3';
    if (flag !== null) {
      url = 'update-division-escalator3';
    }
    const config = {
      'Content-Type': 'application/json',
    };
    const body = {
      company: parseInt(sessionStorage.getItem('company_selected')),
      division: parseInt(sessionStorage.getItem('division_selected')),
      role,
      user,
      old_user: flag,
      company_compliance,
      new_user: user,
    };
    await axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/' + url,
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_DIVISION_USER_SUCCESS,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          // if (reassign) {
          //   dispatch(
          //     editDivisionComplianceData({
          //       id: store.getState().compliance.editComplianceDataId,
          //     })
          //   );
          // }
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_DIVISION_USER,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
          // dispatch(
          //   listDivisionCompliance(
          //     sessionStorage.getItem('company_selected'),
          //     sessionStorage.getItem('division_selected')
          //   )
          // );
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        } else {
          dispatch({
            type: CREATE_DIVISION_USER_FAILED,
            payload: {
              type: 'COMPLIANCE_ADMIN_CREATE',
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 8000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 8000);
      });
  };
var getProgress5 = null;

export const companyDownload =
  (company, company_compliances, task_type) => async (dispatch) => {
    dispatch({
      type: COMPLIANCE_MAPPING_PARAMETER,
      payload: { loading: true, message: 'Uploading in process...' },
    });
    const config = {
      'Content-Type': 'application/json',
    };
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/download-company-compliances',
      data: { company, company_compliances, task_type },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            companyDownloadTask(company, task_type, response.data.tracking_id)
          );
          clearInterval(getProgress5);
          // getProgress5 = setInterval(() => {
          //   dispatch(companyDownloadTask(company, task_type, tracking_id5));
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: COMPLIANCE_MAPPING_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        } else {
          dispatch({
            type: COMPLIANCE_MAPPING_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({
          type: COMPLIANCE_MAPPING_PARAMETER,
          payload: { loading: false, message: '' },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 1000);
      });
  };

export const companyDownloadTask =
  (company, task_type, tracking_id, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    const config = {
      'Content-Type': 'application/json',
    };
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/get-task-status',
      data: {
        task_type,
        tracking_id,
        company,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            dispatch({
              type: COMPLIANCE_MAPPING_PARAMETER,
              payload: { loading: false, message: '' },
            });
            if (flag) dispatch(checkProgressDetails());
            clearInterval(getProgress5);
            if (response.data.isError) {
              if (response.data.hasOwnProperty('url')) {
                if (response.data.url !== '') {
                  let req = new XMLHttpRequest();
                  req.open('GET', BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    'Authorization',
                    sessionStorage.getItem('token')
                  );
                  req.responseType = 'blob';
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'errorlog.txt';
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: COMPLIANCE_ALERT_SET,
                payload: {
                  isAlert: true,
                  isError: true,
                  message: response.data.message,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: COMPLIANCE_ALERT_REMOVE,
                });
              }, 1000);
            } else {
              let req = new XMLHttpRequest();
              req.open('GET', BACKEND_URL_2 + response.data.data, true);
              req.setRequestHeader(
                'Authorization',
                sessionStorage.getItem('token')
              );
              req.responseType = 'blob';
              req.onload = function (event) {
                var blob = req.response;
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Company_Compliance.xlsx';
                link.click();
                dispatch({
                  type: COMPLIANCE_ALERT_SET,
                  payload: {
                    isAlert: true,
                    isError: false,
                    message: 'File Downloaded Successfully',
                  },
                });
                setTimeout(() => {
                  dispatch({
                    type: COMPLIANCE_ALERT_REMOVE,
                  });
                }, 1000);
              };
              req.send();
            }
          } else {
            dispatch({
              type: COMPLIANCE_MAPPING_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            setTimeout(() => {
              dispatch(
                companyDownloadTask(company, task_type, tracking_id, flag)
              );
            }, 2000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress5);
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: COMPLIANCE_MAPPING_PARAMETER,
            payload: { loading: false, message: '' },
          });
          clearInterval(getProgress5);
          dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        } else {
          dispatch({
            type: COMPLIANCE_MAPPING_PARAMETER,
            payload: { loading: false, message: '' },
          });
          clearInterval(getProgress5);
          dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({
          type: COMPLIANCE_MAPPING_PARAMETER,
          payload: { loading: false, message: '' },
        });
        clearInterval(getProgress5);
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 1000);
      });
  };

var getProgress6 = null;

export const divisionDownload =
  (company, division, division_compliances, task_type) => async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    };
    dispatch({
      type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
      payload: { loading: true, message: 'Downloading...' },
    });
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/download-division-compliances',
      data: { company, division, division_compliances, task_type },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          sessionStorage.setItem('divisionDownloadTask', '1');
          dispatch(
            divisionDownloadTask(
              company,
              division,
              task_type,
              response.data.tracking_id
            )
          );
          clearInterval(getProgress6);
          // getProgress6 = setInterval(() => {
          //   dispatch(
          //     divisionDownloadTask(company, division, task_type, tracking_id6)
          //   );
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        } else {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: '' },
        });
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 1000);
      });
  };

export const divisionDownloadTask =
  (company, division, task_type, tracking_id, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    const config = {
      'Content-Type': 'application/json',
    };
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/get-task-status',
      data: {
        task_type,
        tracking_id,
        company,
        division,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            sessionStorage.removeItem('divisionDownloadTask');
            if (flag) {
              dispatch(checkProgressDetails());
            }
            clearInterval(getProgress6);
            if (response.data.isError) {
              dispatch({
                type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
                payload: { loading: false, message: '' },
              });
              if (response.data.hasOwnProperty('url')) {
                if (response.data.url !== '') {
                  let req = new XMLHttpRequest();
                  req.open('GET', BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    'Authorization',
                    sessionStorage.getItem('token')
                  );
                  req.responseType = 'blob';
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'errorlog.txt';
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: COMPLIANCE_ALERT_SET,
                payload: {
                  isAlert: true,
                  isError: true,
                  message: response.data.message,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: COMPLIANCE_ALERT_REMOVE,
                });
              }, 1000);
            } else {
              var req = new XMLHttpRequest();
              req.open('GET', BACKEND_URL_2 + response.data.data, true);
              req.setRequestHeader(
                'Authorization',
                sessionStorage.getItem('token')
              );
              req.responseType = 'blob';
              req.onload = function (event) {
                var blob = req.response;
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Division_Compliance.xlsx';
                dispatch({
                  type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
                  payload: { loading: false, message: '' },
                });
                link.click();
                dispatch({
                  type: COMPLIANCE_ALERT_SET,
                  payload: {
                    isAlert: true,
                    isError: false,
                    message: 'File Downloaded Successfully',
                  },
                });
                setTimeout(() => {
                  dispatch({
                    type: COMPLIANCE_ALERT_REMOVE,
                  });
                }, 1000);
              };
              req.send();
            }
          } else {
            dispatch({
              type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            if (sessionStorage.getItem('divisionDownloadTask') === '1') {
              setTimeout(() => {
                dispatch(
                  divisionDownloadTask(
                    company,
                    division,
                    task_type,
                    tracking_id,
                    flag
                  )
                );
              }, 2000);
            } else {
              dispatch(checkProgressDetails());
              store.dispatch({
                type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
                payload: { loading: false, message: '' },
              });
            }
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress6);
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          clearInterval(getProgress6);
          dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: 'Server Error',
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        } else {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          clearInterval(getProgress6);
          dispatch({
            type: COMPLIANCE_ALERT_SET,
            payload: {
              isAlert: true,
              isError: true,
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: COMPLIANCE_ALERT_REMOVE,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: '' },
        });
        clearInterval(getProgress6);
        setTimeout(() => {
          dispatch({
            type: COMPLIANCE_ALERT_REMOVE,
          });
        }, 1000);
      });
  };

var getProgress21 = null;

export const fileUploadCompliance =
  (file, category, company) => async (dispatch) => {
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    const config = {
      'Content-Type': 'application/json',
    };
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('category', category);
    formdata.append('company', company);
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/upload-company-compliances',
      data: formdata,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            getProgress21Dashboard(
              'upload_company_compliances',
              response.data.tracking_id,
              company
            )
          );
          clearInterval(getProgress21);
          // getProgress21 = setTimeout(() => {
          //   dispatch(
          //     getProgress21Dashboard(
          //       'upload_company_compliances',
          //       tracking_id21,
          //       company
          //     )
          //   );
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: 'Server Error',
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };

export const getProgress21Dashboard =
  (task_type, tracking_id, company, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    const config = {
      'Content-Type': 'application/json',
    };
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/get-task-status',
      data: {
        task_type,
        tracking_id,
        company,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isUploading) {
            store.dispatch({
              type: COMPLIANCE_MAPPING_PARAMETER,
              payload: { loading: false, message: '' },
            });
            if (flag) dispatch(checkProgressDetails());
            clearInterval(getProgress21);
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
            dispatch(
              listCompanyCompliance(
                sessionStorage.getItem('compliance-company')
              )
            );
            if (response.data.isError) {
              if (response.data.hasOwnProperty('url')) {
                if (response.data.url !== '') {
                  let req = new XMLHttpRequest();
                  req.open('GET', BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    'Authorization',
                    sessionStorage.getItem('token')
                  );
                  req.responseType = 'blob';
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'errorlog.txt';
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: 'COMPLIANCE_DELETE_FAILED',
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            } else {
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: 'COMPLIANCE_DELETE_SUCCESS',
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            }
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
          } else {
            store.dispatch({
              type: COMPLIANCE_MAPPING_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            setTimeout(() => {
              dispatch(
                getProgress21Dashboard(
                  'upload_company_compliances',
                  tracking_id,
                  company
                )
              );
            }, 2000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress21);
        } else if (response.data.statuscode === 500) {
          clearInterval(getProgress21);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: 'Server Error',
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          clearInterval(getProgress21);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        clearInterval(getProgress21);
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };

var getProgress22 = null;
var divisionData = [];
export const fileUploadMapCompliance =
  (file, category, company, division) => async (dispatch) => {
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    dispatch({
      type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
      payload: { loading: true, message: 'Uploading...' },
    });
    const config = {
      'Content-Type': 'application/json',
    };
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('category', category);
    formdata.append('company', company);
    formdata.append('division', JSON.stringify(division));
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/upload-division-compliances',
      data: formdata,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          sessionStorage.setItem('triggerGetProgress22', '1');
          dispatch(
            getProgress22MapCompliance(
              'upload_division_compliances',
              response.data.tracking_id,
              company,
              division
            )
          );
          divisionData = division;
          clearInterval(getProgress22);
          // getProgress22 = setTimeout(() => {
          //   dispatch(
          //     getProgress22MapCompliance(
          //       'upload_division_compliances',
          //       tracking_id22,
          //       company,
          //       divisionData
          //     )
          //   );
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: 'Server Error',
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: '' },
        });
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };

export const getProgress22MapCompliance =
  (task_type, tracking_id, company, division, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    const config = {
      'Content-Type': 'application/json',
    };
    axios({
      method: 'POST',
      url: BACKEND_URL_2 + 'project-management/get-task-status',
      data: {
        task_type,
        tracking_id,
        company,
        division,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isUploading) {
            sessionStorage.removeItem('triggerGetProgress22');
            dispatch({
              type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: false, message: '' },
            });
            if (flag) dispatch(checkProgressDetails());
            clearInterval(getProgress22);
            divisionData = [];
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
            dispatch(
              listDivisionCompliance(
                sessionStorage.getItem('company_selected'),
                sessionStorage.getItem('division_selected')
              )
            );
            if (response.data.isError) {
              if (response.data.hasOwnProperty('url')) {
                if (response.data.url !== '') {
                  let req = new XMLHttpRequest();
                  req.open('GET', BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    'Authorization',
                    sessionStorage.getItem('token')
                  );
                  req.responseType = 'blob';
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'errorlog.txt';
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: 'COMPLIANCE_DELETE_FAILED',
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            } else {
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: 'COMPLIANCE_DELETE_SUCCESS',
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            }
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
          } else {
            dispatch({
              type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            if (sessionStorage.getItem('triggerGetProgress22') === '1') {
              setTimeout(() => {
                dispatch(
                  getProgress22MapCompliance(
                    'upload_division_compliances',
                    tracking_id,
                    company,
                    divisionData,
                    flag
                  )
                );
              }, 2000);
            } else {
              dispatch(checkProgressDetails());
              store.dispatch({
                type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
                payload: { loading: false, message: '' },
              });
            }
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          divisionData = [];
          clearInterval(getProgress22);
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          divisionData = [];
          clearInterval(getProgress22);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: 'Server Error',
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          dispatch({
            type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: '' },
          });
          divisionData = [];
          clearInterval(getProgress22);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: 'COMPLIANCE_DELETE_FAILED',
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: '' },
        });
        divisionData = [];
        clearInterval(getProgress22);
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };
