import { checkUserByEmail } from "../login/user";
import axios from "axios";
import store from "../../redux/store";
import {
  BACKEND_URL,
  BACKEND_URL_2,
  CREATE_COMPLIANCE_ADMIN_FAILED,
  CREATE_COMPLIANCE_ADMIN_SUCCESS,
  DEACTIVATE_COMPLIANCE_ADMIN_FAILED,
  DEACTIVATE_COMPLIANCE_ADMIN_SUCCESS,
  LIST_COMPLIANCE_ADMIN_FAILED,
  LIST_COMPLIANCE_ADMIN_SUCCESS,
  REMOVE_COMPLIANCE_ADMIN_ALERT,
  SET_ISOPEN_COMPLIANCE,
  SET_LOGOUT_PROVISION,
} from "../../actions/types";

let listComplianceAdminAbort = null;
export const listComplianceAdmin = () => async (dispatch) => {
  if (listComplianceAdminAbort !== null) {
    listComplianceAdminAbort.cancel("New Call made");
    listComplianceAdminAbort = null;
  }
  listComplianceAdminAbort = axios.CancelToken.source();
  const config = {
    "Content-Type": "application/json",
  };
  const company_id = store.getState().auth.data.company_id;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/list-compliance-admin",
    data: { company_id },
    headers: config,
    cancelToken: listComplianceAdminAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: LIST_COMPLIANCE_ADMIN_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
            data: response.data.data,
          },
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: LIST_COMPLIANCE_ADMIN_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPANY_MANAGEMENT",
            data: null,
          },
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 12000);
    });
};

export const deactivateComplianceAdmin =
  (user, status, role) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const body = { user, role, status };
    await axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/delete-compliance-admin",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: DEACTIVATE_COMPLIANCE_ADMIN_SUCCESS,
            payload: {
              message: response.data.message,
              type: "PROJECT_MANAGEMENT",
            },
          });
          dispatch(listComplianceAdmin());
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: DEACTIVATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              message: response.data.message,
              type: "PROJECT_MANAGEMENT",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 12000);
      });
  };

export const getRole = (role_name) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { role_name: "CUSTOM-USER" };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/get-role",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(createUserProjectRole(role_name, response.data.data.id));
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      } else {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_COMPLIANCE_ADMIN_ALERT,
        });
      }, 12000);
    });
};

export const createNewUser =
  (first_name, last_name, email, role_name) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const name = first_name + " " + last_name;
    const body = { first_name, last_name, name, email };
    await axios({
      method: "POST",
      url: BACKEND_URL + "access/register-user",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          await dispatch(checkUserByEmail(email));
          await dispatch(getRole(role_name));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: response.data.hasOwnProperty("message")
                ? response.data.message
                : "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      });
  };

export const createSuperUser = (data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email: data.email };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/list-user-byemail",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (
        response.data.statuscode === 200 ||
        response.data.statuscode === 400
      ) {
        if (response.data.isNew) {
          dispatch(createSuperUser2(data));
        } else {
          dispatch(assignSuperUser(response.data.data.id));
        }
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.hasOwnProperty("message")
              ? response.data.message
              : "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_COMPLIANCE_ADMIN_ALERT,
        });
      }, 12000);
    });
};

export const createSuperUser2 = (data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const name = data.first_name + " " + data.last_name;
  const body = {
    first_name: data.first_name,
    last_name: data.last_name,
    name,
    email: data.email,
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "access/register-user",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(assignSuperUser(response.data.data));
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.hasOwnProperty("message")
              ? response.data.message
              : "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_COMPLIANCE_ADMIN_ALERT,
        });
      }, 12000);
    });
};

export const assignSuperUser = (user_id) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = {
    company: 1,
    role: 1,
    user: user_id,
    flag: "Company-Management",
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "org/create-usercompanyrole",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(listComplianceAdmin());
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_SUCCESS,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
          dispatch({
            type: SET_ISOPEN_COMPLIANCE,
          });
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 4000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.hasOwnProperty("message")
              ? response.data.message
              : "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_COMPLIANCE_ADMIN_ALERT,
        });
      }, 12000);
    });
};

export const createUserProjectRole =
  (role_name, role_id) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    const body = {
      user: store.getState().user.emailUserId,
      company_id: parseInt(store.getState().auth.data.company_id),
      company: null,
      role: role_id,
      flag: "ACCESS-MANAGEMENT",
    };
    await axios({
      method: "POST",
      url: BACKEND_URL + "org/create-usercompanyrole",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_SUCCESS,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_COMPLIANCE,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 12000);
        } else {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      });
  };

export const listDivisionRoles =
  (role_id, user, role_name) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "POST",
      url: BACKEND_URL_2 + "/project-management/get-division-roles",
      data: { role_name },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(assignProjectUser(response.data.id, user));
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: "Server Error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      });
  };

export const assignProjectUser = (role, user) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  const body = {
    role,
    user,
  };
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/assign-compliance-admin",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(listComplianceAdmin());
        if (response.data.isNew) {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_SUCCESS,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
            dispatch({
              type: SET_ISOPEN_COMPLIANCE,
            });
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 4000);
        } else {
          dispatch({
            type: CREATE_COMPLIANCE_ADMIN_FAILED,
            payload: {
              type: "COMPLIANCE_ADMIN_CREATE",
              message: response.data.message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: REMOVE_COMPLIANCE_ADMIN_ALERT,
            });
          }, 12000);
        }
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      } else {
        dispatch({
          type: CREATE_COMPLIANCE_ADMIN_FAILED,
          payload: {
            type: "COMPLIANCE_ADMIN_CREATE",
            message: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: REMOVE_COMPLIANCE_ADMIN_ALERT,
          });
        }, 12000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: REMOVE_COMPLIANCE_ADMIN_ALERT,
        });
      }, 12000);
    });
};
