import { checkProgressDetails } from "apis/login/user";
import axios from "axios";
import {
  BACKEND_URL_2,
  COMPLIANCE_MASTER_FAILED,
  COMPLIANCE_MASTER_SUCCESS,
  GLOBAL_ALERT_SYSTEM,
  LIST_COMPLIANCE_MASTER_FAILED,
  LIST_COMPLIANCE_MASTER_LOADING,
  LIST_COMPLIANCE_MASTER_SUCCESS,
  MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
  REMOVE_COMPLIANCE_ADMIN_ALERT,
  RESET_IDLE_TIME,
  SET_BUILK_MESSAGE,
  SET_BULK_LOADER,
  SET_EDIT_COMPLIANCE_DATA_API,
  SET_LOGOUT_PROVISION,
} from "../../actions/types";
import { listCompanyCompliance } from "./compliance";

let listComplianceAdminAbort = null;
export const listCompliances =
  (from_id = 0, to_id = 5000, query = "") =>
  async (dispatch) => {
    if (listComplianceAdminAbort !== null) {
      listComplianceAdminAbort.cancel("New Call made");
      listComplianceAdminAbort = null;
    }
    listComplianceAdminAbort = axios.CancelToken.source();
    await dispatch({ type: LIST_COMPLIANCE_MASTER_LOADING, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/list-compliance",
      data: { from_id, to_id, query },
      headers: config,
      cancelToken: listComplianceAdminAbort.token,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch({
            type: LIST_COMPLIANCE_MASTER_SUCCESS,
            payload: {
              message: response.data.message,
              type: "COMPANY_MANAGEMENT",
              data: response.data.data,
              total: response.data.records,
            },
          });
          await dispatch({
            type: LIST_COMPLIANCE_MASTER_LOADING,
            payload: false,
          });
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else {
          dispatch({
            type: LIST_COMPLIANCE_MASTER_FAILED,
            payload: {
              message: response.data.message,
              type: "COMPANY_MANAGEMENT",
              data: null,
            },
          });
          await dispatch({
            type: LIST_COMPLIANCE_MASTER_LOADING,
            payload: false,
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 12000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 12000);
      });
  };

export const createNewCompliance = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  result.days = result.days === "" ? null : result.days;
  result.daysof_cr = result.daysof_cr === "" ? null : result.daysof_cr;
  result.esc1_days = result.esc1_days === "" ? null : result.esc1_days;
  result.esc2_days = result.esc2_days === "" ? null : result.esc2_days;
  result.esc3_days = result.esc3_days === "" ? null : result.esc3_days;
  result.no_of_month = result.no_of_month === "" ? null : result.no_of_month;
  result.no_of_year = result.no_of_year === "" ? null : result.no_of_year;
  result.reminder_day = result.reminder_day === "" ? null : result.reminder_day;
  result.start_month = result.start_month === "" ? null : result.start_month;
  result.start_year = result.start_year === "" ? null : result.start_year;
  delete result.files;
  const body = result;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/create-compliance",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        if (
          values.files.length === 0 ||
          values.files === null ||
          values.files === undefined
        ) {
          dispatch({
            type: COMPLIANCE_MASTER_SUCCESS,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_CREATE_SUCCESS",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 2000);
        } else {
          dispatch(
            uploadDocuments(
              "COMPLIANCE_META",
              values.is_company_level ? 1 : 0,
              response.data.id,
              response.data.cpn_no,
              values.files,
              response.data.message
            )
          );
        }
        dispatch(listCompliances());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

export const editCompliance = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  result.days = result.days === "" ? null : result.days;
  result.daysof_cr = result.daysof_cr === "" ? null : result.daysof_cr;
  result.esc1_days = result.esc1_days === "" ? null : result.esc1_days;
  result.esc2_days = result.esc2_days === "" ? null : result.esc2_days;
  result.esc3_days = result.esc3_days === "" ? null : result.esc3_days;
  result.no_of_month = result.no_of_month === "" ? null : result.no_of_month;
  result.no_of_year = result.no_of_year === "" ? null : result.no_of_year;
  result.reminder_day = result.reminder_day === "" ? null : result.reminder_day;
  result.start_month = result.start_month === "" ? null : result.start_month;
  result.start_year = result.start_year === "" ? null : result.start_year;
  delete result.files;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/edit-compliance",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        if (
          values.files.length === 0 ||
          values.files === null ||
          values.files === undefined
        ) {
          dispatch({
            type: COMPLIANCE_MASTER_SUCCESS,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_CREATE_SUCCESS",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 2000);
        } else {
          dispatch(
            uploadDocuments(
              "COMPLIANCE_META",
              values.is_company_level ? 1 : 0,
              response.data.id,
              response.data.cpn_no,
              values.files,
              response.data.message
            )
          );
        }
        dispatch(listCompliances());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

export const deactivateCompliance = (data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };

  const body = data;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/delete-compliance",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: COMPLIANCE_MASTER_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_DELETE_SUCCESS",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
        dispatch(listCompliances());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server error",
            type: "COMPLIANCE_DELETE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_DELETE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

export const editComplianceData = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  if (values.is_company_level) {
    values.company = parseInt(sessionStorage.getItem("company_selected"));
    if (window.location.pathname === "/app/compliance-detailed-view") {
      values.company = parseInt(sessionStorage.getItem("compliance-company"));
    }
  }
  if (sessionStorage.getItem("is_report_tab") === "0") {
    values.company = parseInt(sessionStorage.getItem("company_selected_top"));
    sessionStorage.removeItem("is_report_tab");
  }
  if (sessionStorage.getItem("is_report_tab") === "1") {
    values.company = parseInt(sessionStorage.getItem("company_selected"));
    sessionStorage.removeItem("is_report_tab");
  }

  const body = values;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-compliance",
    data: body,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SET_EDIT_COMPLIANCE_DATA_API,
          payload: response.data.data[0],
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

let editDivisionComplianceDataAbort = null;
export const editDivisionComplianceData = (values) => async (dispatch) => {
  if (editDivisionComplianceDataAbort !== null) {
    editDivisionComplianceDataAbort.cancel("New Call made");
    editDivisionComplianceDataAbort = null;
  }
  editDivisionComplianceDataAbort = axios.CancelToken.source();
  const config = {
    "Content-Type": "application/json",
  };
  values.company = parseInt(sessionStorage.getItem("company_selected"));
  values.division = parseInt(sessionStorage.getItem("division_selected"));
  const body = values;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-division-compliance",
    data: body,
    headers: config,
    cancelToken: editDivisionComplianceDataAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SET_EDIT_COMPLIANCE_DATA_API,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

let myDivisionComplianceDataAbort = null;
export const myDivisionComplianceData = (values) => async (dispatch) => {
  if (myDivisionComplianceDataAbort !== null) {
    myDivisionComplianceDataAbort.cancel("New Call made");
    myDivisionComplianceDataAbort = null;
  }
  myDivisionComplianceDataAbort = axios.CancelToken.source();
  const config = {
    "Content-Type": "application/json",
  };

  values.company = parseInt(sessionStorage.getItem("company_selected_top"));
  values.division = parseInt(sessionStorage.getItem("division_selected_top"));

  const body = values;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-division-compliance",
    data: body,
    headers: config,
    cancelToken: myDivisionComplianceDataAbort.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: SET_EDIT_COMPLIANCE_DATA_API,
          payload: response.data.data,
        });
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

export const uploadDocuments =
  (category, is_company_level, compliance_id, cpn_no, data, message) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    let formdata = new FormData();
    formdata.append("file", data[data.length - 1]);
    formdata.append("category", category);
    formdata.append("is_company_level", is_company_level);
    formdata.append("cpn_no", cpn_no);

    const body = formdata;
    if (is_company_level === 1) {
      formdata.append("company", sessionStorage.getItem("company_selected"));
    }
    await axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/upload-compliance",
      data: body,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (data.length === 1) {
            dispatch({
              type: COMPLIANCE_MASTER_SUCCESS,
              payload: {
                message: message,
                type: "COMPLIANCE_CREATE_SUCCESS",
              },
            });
            setTimeout(() => {
              dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
            }, 2000);
            dispatch(listCompliances());
            return;
          } else {
            data.pop();
            dispatch(
              uploadDocuments(
                category,
                is_company_level ? 1 : 0,
                compliance_id,
                cpn_no,
                data,
                message
              )
            );
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: COMPLIANCE_MASTER_FAILED,
            payload: {
              message: "Server Error",
              type: "COMPLIANCE_CREATE_FAILED",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 2000);
        } else {
          dispatch({
            type: COMPLIANCE_MASTER_FAILED,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_CREATE_FAILED",
            },
          });
          setTimeout(() => {
            dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
          }, 2000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      });
  };

export const editDivisionCompliance = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  delete result.files;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/edit-div-compliance-info",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch({
          type: COMPLIANCE_MASTER_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_SUCCESS",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
        dispatch(listCompliances());
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

export const downloadFile = (values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  var result = { ...values };
  delete result.fileName;
  await axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/get-download-url",
    data: result,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        var req = new XMLHttpRequest();
        req.open("GET", BACKEND_URL_2 + response.data.url, true);
        req.setRequestHeader("Authorization", sessionStorage.getItem("token"));
        req.responseType = "blob";
        req.onload = function (event) {
          var blob = req.response;
          var fileName = values.fileName; //if you have the fileName header available
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        };
        req.send();
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: "Server Error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      } else {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: true,
            alertType: "FAIL",
            alertMessage: response.data.message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: GLOBAL_ALERT_SYSTEM,
            payload: {
              isAlert: false,
              alertType: null,
              alertMessage: null,
            },
          });
        }, 500);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({
          type: GLOBAL_ALERT_SYSTEM,
          payload: {
            isAlert: false,
            alertType: null,
            alertMessage: null,
          },
        });
      }, 500);
    });
};

var cameraStreamUpdates = null;
export const downloadFileUrl = (values) => async (dispatch) => {
  const url = "project-management/get-download-url";
  const config = {
    "Content-Type": "application/json",
  };
  if (cameraStreamUpdates !== null) {
    cameraStreamUpdates.cancel("New Call made");
    cameraStreamUpdates = null;
  }
  cameraStreamUpdates = axios.CancelToken.source();
  var result = { ...values };
  delete result.fileName;
  try {
    const response = await axios.post(BACKEND_URL_2 + url, values, {
      headers: config,
      data: result,
      cancelToken: cameraStreamUpdates.token,
    });
    if (response.data.statuscode === 200) {
      cameraStreamUpdates = null;
      return BACKEND_URL_2 + response.data.url;
    } else {
      cameraStreamUpdates = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const deleteComplianceFiles = (data, values) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/delete-files",
    data: data,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(editComplianceData(values));
        dispatch({
          type: COMPLIANCE_MASTER_SUCCESS,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_SUCCESS",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      } else {
        dispatch({
          type: COMPLIANCE_MASTER_FAILED,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_CREATE_FAILED",
          },
        });
        setTimeout(() => {
          dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
        }, 2000);
      }
    })
    .catch((error) => {
      setTimeout(() => {
        dispatch({ type: REMOVE_COMPLIANCE_ADMIN_ALERT });
      }, 2000);
    });
};

var getProgress = null;
var categoryType = null;
var selectedCompany = null;

export const fileUploadCompliance =
  (file, category, is_company_level) => async (dispatch) => {
    categoryType = category;
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    dispatch({
      type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
      payload: { loading: true, message: "Uploading..." },
    });
    const config = {
      "Content-Type": "application/json",
    };
    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("category", category);
    formdata.append("is_company_level", is_company_level);
    if (is_company_level === 1) {
      formdata.append("company", sessionStorage.getItem("company_selected"));
      selectedCompany = sessionStorage.getItem("company_selected");
    }
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/upload-compliance",
      data: formdata,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          dispatch(
            getProgressDashboard(categoryType, response.data.tracking_id)
          );
          clearInterval(getProgress);
          // getProgress = setInterval(() => {
          //   dispatch(getProgressDashboard(categoryType, tracking_id1));
          // }, 2000);
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: "Server Error",
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };

export const getProgressDashboard =
  (task_type, tracking_id, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/get-task-status",
      data: {
        task_type,
        tracking_id,
        company: selectedCompany,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isUploading) {
            dispatch({
              type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: false, message: "" },
            });
            if (flag) dispatch(checkProgressDetails());
            clearInterval(getProgress);
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
            if (selectedCompany === null) {
              dispatch(listCompliances());
            } else {
              dispatch(
                listCompanyCompliance(
                  sessionStorage.getItem("company_selected")
                )
              );
            }
            selectedCompany = null;

            if (response.data.isError) {
              if (response.data.hasOwnProperty("url")) {
                if (response.data.url !== "") {
                  let req = new XMLHttpRequest();
                  req.open("GET", BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    "Authorization",
                    sessionStorage.getItem("token")
                  );
                  req.responseType = "blob";
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "errorlog.txt";
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: "COMPLIANCE_DELETE_FAILED",
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            } else {
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: "COMPLIANCE_DELETE_SUCCESS",
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            }
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
          } else {
            dispatch({
              type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            setTimeout(() => {
              dispatch(getProgressDashboard(categoryType, tracking_id));
            }, 3000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress);
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          clearInterval(getProgress);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: "Server Error",
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          clearInterval(getProgress);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        clearInterval(getProgress);
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };

var getProgress2 = null;

export const masterDownload = (compliances, task_type) => async (dispatch) => {
  await dispatch({ type: SET_BULK_LOADER, payload: true });
  dispatch({
    type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
    payload: { loading: true, message: "Downloading..." },
  });
  const config = {
    "Content-Type": "application/json",
  };
  axios({
    method: "POST",
    url: BACKEND_URL_2 + "project-management/download-compliances",
    data: { compliances, task_type },
    headers: config,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(masterDownloadTask(task_type, response.data.tracking_id));
        clearInterval(getProgress2);
        // getProgress2 = setInterval(() => {
        //   dispatch(masterDownloadTask(task_type, tracking_id2));
        // }, 2000);
      } else if (response.data.statuscode === 403) {
        await dispatch({ type: SET_LOGOUT_PROVISION });
      } else if (response.data.statuscode === 500) {
        dispatch({
          type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        await dispatch({ type: SET_BULK_LOADER, payload: false });
        dispatch({
          type: SET_BUILK_MESSAGE,
          payload: {
            message: "Server Error",
            type: "COMPLIANCE_DELETE_FAILED",
            error: true,
          },
        });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      } else {
        dispatch({
          type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        await dispatch({ type: SET_BULK_LOADER, payload: false });
        dispatch({
          type: SET_BUILK_MESSAGE,
          payload: {
            message: response.data.message,
            type: "COMPLIANCE_DELETE_FAILED",
            error: true,
          },
        });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      }
    })
    .catch((error) => {
      dispatch({
        type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
        payload: { loading: false, message: "" },
      });
      dispatch({ type: SET_BULK_LOADER, payload: false });
      setTimeout(() => {
        dispatch({
          type: SET_BUILK_MESSAGE,
          payload: {
            message: null,
            type: null,
            error: false,
          },
        });
      }, 2000);
    });
};

export const masterDownloadTask =
  (task_type, tracking_id, flag = false) =>
  async (dispatch) => {
    dispatch({ type: RESET_IDLE_TIME, payload: true });
    setTimeout(() => {
      dispatch({ type: RESET_IDLE_TIME, payload: false });
    }, 500);
    await dispatch({ type: SET_BULK_LOADER, payload: true });
    const config = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: BACKEND_URL_2 + "project-management/get-task-status",
      data: {
        task_type,
        tracking_id,
      },
      headers: config,
    })
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (!response.data.isDownloading) {
            dispatch({
              type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: false, message: "" },
            });
            clearInterval(getProgress2);
            if (flag) dispatch(checkProgressDetails());
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
            if (response.data.isError) {
              if (response.data.hasOwnProperty("url")) {
                if (response.data.url !== "") {
                  let req = new XMLHttpRequest();
                  req.open("GET", BACKEND_URL_2 + response.data.url, true);
                  req.setRequestHeader(
                    "Authorization",
                    sessionStorage.getItem("token")
                  );
                  req.responseType = "blob";
                  req.onload = function (event) {
                    var blob = req.response;
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "errorlog.txt";
                    link.click();
                  };
                  req.send();
                }
              }
              dispatch({
                type: SET_BUILK_MESSAGE,
                payload: {
                  message: response.data.message,
                  type: "COMPLIANCE_DELETE_FAILED",
                  error: true,
                },
              });
              setTimeout(() => {
                dispatch({
                  type: SET_BUILK_MESSAGE,
                  payload: {
                    message: null,
                    type: null,
                    error: false,
                  },
                });
              }, 2000);
            } else {
              var req = new XMLHttpRequest();
              req.open("GET", BACKEND_URL_2 + response.data.data, true);
              req.setRequestHeader(
                "Authorization",
                sessionStorage.getItem("token")
              );
              req.responseType = "blob";
              req.onload = function (event) {
                var blob = req.response;
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "Master_Compliance.xlsx";
                link.click();
              };
              req.send();
            }
            await dispatch({
              type: SET_BULK_LOADER,
              payload: false,
            });
          } else {
            dispatch({
              type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
              payload: { loading: true, message: response.data.message },
            });
            setTimeout(() => {
              dispatch(masterDownloadTask(task_type, tracking_id, flag));
            }, 3000);
          }
        } else if (response.data.statuscode === 403) {
          await dispatch({ type: SET_LOGOUT_PROVISION });
          clearInterval(getProgress2);
        } else if (response.data.statuscode === 500) {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          clearInterval(getProgress2);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: "Server Error",
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        } else {
          dispatch({
            type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
            payload: { loading: false, message: "" },
          });
          clearInterval(getProgress2);
          await dispatch({ type: SET_BULK_LOADER, payload: false });
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: response.data.message,
              type: "COMPLIANCE_DELETE_FAILED",
              error: true,
            },
          });
          setTimeout(() => {
            dispatch({
              type: SET_BUILK_MESSAGE,
              payload: {
                message: null,
                type: null,
                error: false,
              },
            });
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: MASTER_DOWNLOAD_CERTIFICATE_PARAMETER,
          payload: { loading: false, message: "" },
        });
        clearInterval(getProgress2);
        dispatch({ type: SET_BULK_LOADER, payload: false });
        setTimeout(() => {
          dispatch({
            type: SET_BUILK_MESSAGE,
            payload: {
              message: null,
              type: null,
              error: false,
            },
          });
        }, 2000);
      });
  };
